import { authActions } from "../../store/auth-Slice";
import { getRefreshToken } from "../Api";

const getRequest = async (uri, headers, dispatch) => {
    let response;
    try {
        response = await fetch(uri, {
            method: 'GET',
            headers: {
                Authorization: headers.token,
                Client: headers.client,
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Handle 401 error: acquire new token and retry the request
                const newApiData = await getRefreshToken();

                // if token expired and user navigates to new page - loaders cant use dispatch so reload as getRefreshToken as passed new token and refresh tokens to session storage.
                dispatch ? dispatch(authActions.UpdateToken(newApiData)) :
                    window.location.reload();

                const newresponseData = await getRequest(uri, newApiData, dispatch);
                return newresponseData
            } else {

                throw new Error({
                    status: response.status,
                    message: "Fetching failed: " + response.statusText
                });
            }

        } else {

        }

        const responseData = await response.json();
        if (responseData.data) {

            // clear logs api returns only response.success so check if data not not exit first. 
            if (responseData.data === undefined || responseData.data === null) {
                if (responseData.success) {
                    return responseData.success;
                }
            }

            // most other api return data.success
            if (responseData.data.success === false) {
                throw new Error("get failed");
            }
        } else {
            return responseData;
        }

        return responseData;
    } catch (error) {
        let errorObject;
        if (response && response) {
            errorObject = {
                status: response.status,
                message: response.statusText
            }
        } else {
            errorObject = {
                status: "Unexpected Crash",
                message: error.message
            }
        }

        throw (errorObject);
    }
};


export default getRequest;
