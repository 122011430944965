import React, { useRef } from 'react'

import loginImage from "../../../assets/Portal Login Image/LoginImage.jpeg";

import classes from "./RegisterPage.module.css";

import Register2 from '../../UI/Cards/FormCard/Register2';

import Register from '../../UI/Cards/FormCard/Register';

const RegisterPage = () => {

    return (
        <div className={classes["page-layout"]}>

            <div className={classes.container} >
                <img className={classes.imageContainer} src={loginImage} loading={'eager'} />

                {/* <Login className={classes.card} formType="emailPassword" HandleShowModal={HandleShowModal} /> */}
                <Register2 extraLinks={[{ LinkText: "Already have an account?", LinkTextBold: "Sign In" }]} />
            </div>


        </div>

    )
}

export default RegisterPage