import React, { useRef, useImperativeHandle } from "react";

import classes from './Input.module.css';

const Input = React.forwardRef((props, ref) => {

    const inputRef = useRef();

    const activate = () => {
        inputRef.current.focus()
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {

            event.preventDefault();

          event.preventDefault();

        }
    };

    useImperativeHandle(ref, () => {
        return {
            focus: activate
        }
    });

    return (
        <input
            className={`${classes.input} ${props.propsClassName} ${props.isValid === false ? classes.invalid : ''}`}
            placeholder={props.placeholder}
            ref={inputRef}
            type={props.type || 'text'}
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyPress={handleKeyPress}
            style={props.style}
        />
    );
});

export default Input;