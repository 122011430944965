import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { addGroup, getGroups } from '../../Api Methods/Api';
import GroupsGrid from './GroupsGrid';
import Button1 from '../../components/UI/Button/Button1';
import Modal from '../../components/Modal/Modal';
import Dropdown from '../../components/forms/DropdownCreateGroup';
import InputForm from '../../components/forms/InputFormCreateGroup';
import DataManager from '../../hooks/DataManager';
import Stats2 from '../../components/UI/Stats/Stats2';
import DeviceLoadingGrid from '../../components/GridList/DeviceLoadingGrid';
import classes from './GroupsGrid.module.css'
import check from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'

const Groups = () => {
  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);
  const [title, setTitle] = useState("Create group");
  const [groups, setGroups] = useState();
  const [modalComponent, setModalComponent] = useState();
  const [name, setName] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('');
  const [update, setUpdate] = useState(0);

  const [isLoading, setIsLoadng] = useState(true)

  const dataManager = new DataManager();

  const apiData = {
    token,
    client
  }

  useEffect(() => {
  }, [selectedGroup])

  useEffect(() => {
  }, [name])

  const dispatch = useDispatch();

  const HandleGetGroups = async () => {
    const data = await getGroups(apiData, dispatch)
    setGroups(data)
    setIsLoadng(false)
    sessionStorage.setItem('groups', JSON.stringify(data));
  }

  useEffect(() => {
    HandleGetGroups()
  }, [update])

  const ModalDialog = useRef();

  const handleCreateGroups = () => {
    const groupNames = groups.map(group => group.name);
    setModalComponent(
      <div>
        <div>
          <InputForm label={"Enter name of group"} setName={setName}></InputForm>
        </div>
        <br></br>
        <div>
          <Dropdown label={"Please select the parent group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
        </div>
        <br></br>
        <Button1 onClick={createGroup}>Create</Button1>
      </div>
    )  
    HandleShowModal();
  }

  const showError = () => {
    setModalComponent(
      <div>
        <div>
          <h3>Error</h3>
        </div>
        <br></br>
        <div>
         <p>Error creating group, please try again later.</p>
        </div>
        <br></br>
        <Button1 onClick={HandleCloseModal}>Close</Button1>
      </div>
    )  
    HandleShowModal();
  }

  const successModal = () => {
    setTitle('Success')
    setModalComponent(
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.ModalIcon} src={check}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>Action completed successfully</h3>
        </div>   
        <br></br>
        <br></br>
        <div>
          <br></br>
          <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
        </div>
      </div>
    )
    HandleShowModal();
  }

  const createGroup = async () => {
    const _selectedGroup = dataManager.getItem('selectedGroup');
    const _groupName = dataManager.getItem('groupName');

    const parentGroup = groups.find(group => group.name === _selectedGroup);
    const parentID = parentGroup["id"];

    const body = {
      groupName: _groupName, 
      parentID: parentID
    }

    if (_groupName !== '' && parentID !== ''){
      const response = await addGroup(apiData, body, dispatch)
      if (response.success){
        HandleGetGroups();
        HandleCloseModal();
        successModal();
        //refresh
      } else {
        showError();
        //error
      }
    } else {
      //invalid form
    }
  }

  const HandleShowModal = () => {
    ModalDialog.current.open();
  };

  const HandleCloseModal = () => {
    ModalDialog.current.close();
    setTitle("Create group")
  };

  return ( 
    <>
      <Modal ref={ModalDialog} title={"Create group"} modalName={""} HandleShowModal={HandleShowModal}>
          {modalComponent}
      </Modal>

      <Await resolve={groups}>
        {(loadedTotalGroups) => 
          <div style={{ display: "flex", width: "100%" }}>
            {groups && <Stats2 title={"Total groups"} value={loadedTotalGroups.length}  isLoadingStats={isLoading} isStatic={true} />}
          </div>
        }
      </Await>
              

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px", marginTop:"15px"}}>
          <Button1 style={{height: "50px", width: "160px", "font-size": "16px"}} onClick={handleCreateGroups}>Create new group</Button1>
        </div>

        {groups ? (
          <GroupsGrid GroupsList={groups} handleCreateGroupClick={handleCreateGroups} setUpdate={setUpdate} update={update}></GroupsGrid>
        ) : (
          <div style={{ display: "flex", width: "100%" }}>
          <DeviceLoadingGrid
            CardCount={20}
          />
        </div>
        )}
      </div>
    </>
  );
}

export default Groups

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');

  const apiData = {
    token,
    client
  }

  return defer({
    groups: getGroups(apiData)
  })
}