import React, { useState, useEffect } from 'react';
import classes from "./CategoryFilter.module.css";

import arrrowIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Filter/__Page - Forward.svg"
import Button1 from '../UI/Button/Button1';

const CategoryFilter = ({ categories, setCategoryFilter, categoryFilter }) => {


    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        let updatedSelectedCategories = [...selectedCategories];

        if (event.target.checked) {
            updatedSelectedCategories.push(selectedCategory);
        } else {
            updatedSelectedCategories = updatedSelectedCategories.filter(category => category !== selectedCategory);
        }

        setSelectedCategories(updatedSelectedCategories);
        setCategoryFilter(updatedSelectedCategories);
        setShowDropdown(false);
    };

    useEffect(() => {
        if (categoryFilter != 'all') {
            setSelectedCategories(categoryFilter)
        }
    }, [categoryFilter])

    const toggleDropdown = (event) => {
        event.preventDefault();
        setShowDropdown(!showDropdown);
    };

    return (
        <div className={classes.categoryFilter}>

            <Button1 style={{ height: "50px", fontSize: "16px", minWidth: "202px", justifyContent: "space-between" }} className={` ${classes.button}`} img={arrrowIcon} onClick={toggleDropdown}>
                {selectedCategories.length === 0 ? "Filters" :
                    // selectedCategories.length === 1 ? "Category: " + selectedCategories[0] :
                    `${selectedCategories}`
                }
            </Button1>

            {showDropdown && (
                <div className={`${classes.dropdown} ${"fade-in-from-top"}`}>
                    {categories.map((category, index) => (
                        <label
                            key={category}
                            className={`${classes.categoryLabel} ${category === "Favorited" || category === "Not Favorited" ? classes.disabled : ''}`}
                            htmlFor={`checkbox-${category}`}
                        >
                            <input
                                className={classes.filterItem}
                                id={`checkbox-${category}`}
                                type="checkbox"
                                value={category}
                                checked={selectedCategories.includes(category)}
                                onChange={handleCategoryChange}
                                disabled={category === "Favorited" || category === "Not Favorited"}
                            />
                            {category}
                        </label>
                    ))}
                </div>
            )
            }
        </div >
    );
};

export default CategoryFilter;
