import React, { useEffect, useState } from "react";
import { getIglooLocks } from "../../Api Methods/Api";
import { useDispatch, useSelector } from "react-redux";
import { useScrollTrigger } from "@mui/material";
import { Await } from "react-router-dom";
import PadlocksList from "./padlocksList";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import Stats2 from "../../components/UI/Stats/Stats2";

const Padlocks = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [locks, setPadlocks] = useState([])
    const [update, setUpdate] = useState(0)
    const filtering = ""
    const [isLoading, setIsLoading] = useState(true)

    //Pull data on mount
    useEffect(() => {
        const HandleGetLocks = async () => {
            const response = await getIglooLocks(apiData, dispatch);
            setPadlocks(response.data[0])
            setIsLoading(false)
        }

        HandleGetLocks();
    }, [update])


    return (
        <Await resolve={locks}>
                <div style={{ width: "100%" }}>
                    <Stats2 title={"Total padlocks"} value={locks.length}  isLoadingStats={isLoading} isStatic={true} />
                    <br></br>
                    {isLoading ? (
                        <div style={{ display: "flex", width: "100%" }}>
                            <DeviceLoadingGrid
                                CardCount={20}
                            />
                        </div>
                        ) : 
                        (
                            <PadlocksList 
                                locks={locks}
                                filtering={filtering}
                                update={update}
                                setUpdate={setUpdate}
                            />      
                        )
                    }
                    
                </div>
           
        </Await>
    );
}

export default Padlocks;

