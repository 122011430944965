import React, { Children, useEffect, useState, useRef } from 'react'
import classes from "./LockContentLayout.module.css";
import arrowIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import Modal from '../../../../../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import MasterPinIcon from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/visibility.png'
import OTPIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Access.svg";
import RemoveLockIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import GenerateLockOnTimePassCodeForm from '../../EditAutomations/GenerateLockOnTimePassCodeForm';
import { GenerateLockOTP, GenerateLockOTPAPI, getPadlockPincodes, removeLockAPI } from '../../../../../../Api Methods/Api';
import Button1 from '../../../../Button/Button1';
import ShowOTPPopup from './ShowOTPPopup';
import { Await } from 'react-router-dom';
import PasscodeContentLayout from './PasscodeContentLayout';

const LockContentLayout = ({ outputPinName, iglooLocks, setRefreshVirtualSources }) => {

    const { lockID, adminKey, battery, batteryReadAt, masterPin, outputPin, type } = iglooLocks;
    const PinName = outputPinName[0].pinName;
    const OutputPin = outputPinName[0].Pin;

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const [passcodes, setPasscodes] = useState([]);
    
    const apiData = {
        token,
        client
    }

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        //reset model screens
        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("Generate Access")
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(null);


    const [OTPCode, setOTPCode] = useState(null);

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
                setModalPageIndex(null);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    //constants for updating content on each modal page
    const [isFormValid, setIsFormValid] = useState(false);

    // const [ListArray, setListArray] = useState(OutPutOptions)

    const HandelResetModal = () => {
        setModalPageIndex(null);
    }



    // re-render modal to show new modal componet. 
    useEffect(() => {
        switch (modalPageIndex) {
            case 1:
                setModalComponent(<GenerateLockOnTimePassCodeForm GenerateOTP={GenerateOTP} />)
                HandleShowModal();
                break;
            case 2:
                setModalTitle("Lock Access Code")
                setModalComponent(<ShowOTPPopup pincode={OTPCode} closeModal={HandleCloseModal}/>)
                break;
        }

    }, [modalPageIndex])

    const dispatch = useDispatch();

    const GenerateOTP = async (otpName, startDate, endDate, type) => {

        const body = {
            lockID: lockID,
            startDate: startDate,
            endDate: endDate,
            type: type,
            uuid: DeviceUUID,
            otpName: otpName,
        }

        const responseData = await GenerateLockOTPAPI(apiData, body, dispatch)

        if (responseData && responseData.success) {
            setOTPCode(responseData.data.pin);
            //HandleCloseModal();
            setModalPageIndex(2)
            setRefreshVirtualSources(true);
        } else {
            // console.log(responseData.msg);
            setOTPCode(responseData.msg);


            //HandleCloseModal();
            setModalPageIndex(2)


            //HandleCloseModal();
        }
    }

    // const removeLock = async () => {
    //     const responseData = await removeLockAPI(apiData, DeviceUUID, OutputPin, dispatch)

    //     if (responseData.success) {
    //         setRefreshVirtualSources(true);
    //         HandleCloseModal();
    //     }
    // }


    const HandleGetPincodes = async () => {
        setModalTitle("Master PIN");

        // const loadedPasscodes = await getPadlockPincodes(apiData, lockID, dispatch) // Wait for passcodes to load
        // setPasscodes(loadedPasscodes.data); 
        // console.log(loadedPasscodes.data)// Store the loaded data in state
        
        // // Directly use the resolved passcodes
        // setModalComponent(
        //     loadedPasscodes.data.map((passcode, index) => (
        //         <div key={index}>
        //             <PasscodeContentLayout passcodeData={passcode} />
        //         </div>
        //     ))
        // );
        
        setModalComponent(
            <div style={{
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                justifyContent: "center", 
                textAlign: "center", 
                height: "100%"
            }}>
                <h3 className={classes.title}>{"Master PIN for " + lockID}</h3>
                <h1 style={{ color: "#168aad" }}>{masterPin}</h1>
                <Button1 onClick={HandleCloseModal}>Close</Button1>
            </div>
        );
        HandleShowModal()
    }

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: modalPageIndex === 1 ? "665px" : "250px"}} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>

            <div className={classes.LockContentLayout} >
                <div className={classes["left-wrapper"]}>
                    <h3 className={classes.title}>{"Smart Lock"}</h3>
                    <h3 className={classes.subtitle}>{lockID}</h3>
                    {OTPCode && "OTP: " + OTPCode}
                </div>

                <div className={classes["right-wrapper"]}>
                    <h3 className={classes.pinName}>{PinName}</h3>

                    <div className={classes["Icons-Container"]} >
                        <img className={classes.Icon} src={OTPIcon} onClick={() => setModalPageIndex(1)} />
                        <img className={classes.RemoveLockIcon} src={MasterPinIcon} onClick={HandleGetPincodes} />
                    </div>
                </div>
            </div >
        </>
    )
}

export default LockContentLayout;




