import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getPagedDevicesAPI } from "../../Api Methods/Api";
import { Await, useNavigate } from "react-router-dom";
import DevicesList from "../../components/GridList/DevicesList";
import DataManager from "../../hooks/DataManager";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import classes from './offlineSites.module.css'
import Stats2 from "../../components/UI/Stats/Stats2";
import SearchBar from "../SearchView/Search/SearchBar";
import { filter } from "rxjs";

const OfflineSites = () => {

    const dataManager = new DataManager();
    const [offlineSites, setOfflineSites] = useState([])
    const [offlineSitesMonth, setOfflineSitesMonth] = useState([])
    const [offlineSitesLessMonth, setOfflineSitesLessMonth] = useState([])
    const [offlineSitesNoUsers, setOfflineSitesNoUsers] = useState([])
    const [totalPages, setTotalPages] = useState([])
    const [totalDevices, setTotalDevices] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [update, setUpdate] = useState(0)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]); // Track filtered data

    const [pageFetchedIndex, setPageFetchedIndex] = useState(1)
    const [pageSize, setPageSize] = useState(1000)

    const apiData = {
        token,
        client
    }

    useEffect(() => {
      const getOfflineSites = async () => {
        // Fetch data from API
        const responseData = await getPagedDevicesAPI(apiData, 50000, 1, 0, 1, 0, 0, dispatch);
        const totalDevices = responseData[0].totalDevices["COUNT(*)"];
        const devices = responseData[0].devices;
    
        // Filter by device type only once
        const filteredDevices = devices.filter((device) => 
          ["A", "AB", "ESP"].includes(device.type)
        );
    
        // Pre-calculate dates to avoid recalculating in each filter
        const now = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(now.getMonth() - 1);
    
        // Process filtered devices in a single pass
        const offlineSitesMonth = [];
        const offlineSitesLessMonth = [];
        const offlineSitesNoUsers = [];
    
        filteredDevices.forEach((device) => {
          const lastLoggedInDate = new Date(device.lastLoggedIn);
          
          if (lastLoggedInDate < oneMonthAgo) offlineSitesMonth.push(device);
          if (lastLoggedInDate > oneMonthAgo) offlineSitesLessMonth.push(device);
          if (device.userCount > 0) offlineSitesNoUsers.push(device);
        });
    
        // Sort data once
        const sortedDevices = [...filteredDevices].sort(
          (a, b) => new Date(a.lastLoggedIn) - new Date(b.lastLoggedIn)
        );
    
        // Set state
        setFilteredData(sortedDevices);
        setOfflineSites(sortedDevices);
        setOfflineSitesMonth(offlineSitesMonth);
        setOfflineSitesLessMonth(offlineSitesLessMonth);
        setOfflineSitesNoUsers(offlineSitesNoUsers);
    
        // Pagination
        setTotalPages(Math.ceil(totalDevices / pageSize));
        setTotalDevices(totalDevices);
        setPageSize(totalDevices);
        setIsLoading(false);
      };
    
      getOfflineSites();
    }, []);
    

    const applyFilter = (filterData) => {
      setFilteredData(filterData); // Update filteredData based on clicked filter
    };

    useEffect(() => {
      const handleDeviceFilter = () => {
        const filtered = offlineSites.filter((item) =>
          Object.values(item)
            .some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        );
        dataManager.setItem('offlineSearchTerm', searchTerm); // Store search term
        setFilteredData(filtered); // Update filtered data
      };
    
      if (searchTerm !== dataManager.getItem('offlineSearchTerm')) {
        handleDeviceFilter(); // Call filtering function
      }
    }, [searchTerm, offlineSites]);
    


    
    return (
      <Await resolve={offlineSites}>
        {(loadedOfflineSites) => {
         
          return (
            <>
            <div style={{ width: "100%"}}>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <div
                onClick={() => {
                 applyFilter(loadedOfflineSites); 
                }}
                style={{ cursor: "pointer", width:"350px"}} 
              >
              <Stats2
                title={"Total offline controllers"}
                value={loadedOfflineSites.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
            <div
                onClick={() => {
                  applyFilter(offlineSitesMonth) 
                }}
                style={{ cursor: "pointer", width:"350px" }} 
              >
              <Stats2
                title={"Offline for more than 1 month"}
                value={offlineSitesMonth.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
            <div
                onClick={() => {
                 
                  applyFilter(offlineSitesLessMonth) 
                }}
                style={{ cursor: "pointer", width:"350px" }} 
              >
              <Stats2
                title={"Offline for less than 1 month"}
                value={offlineSitesLessMonth.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
            <div
                onClick={() => {
                  
                  applyFilter(offlineSitesNoUsers) 
                }}
                style={{ cursor: "pointer", width:"350px" }} 
              >
              <Stats2
                title={"Active offline controllers"}
                value={offlineSitesNoUsers.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
          </div>

            <br></br>
            {isLoading ? (
              <div style={{ display: "flex", width: "100%" }}>
                <DeviceLoadingGrid
                  CardCount={
                    20
                  }
                />
              </div>
            ) : (
              <>
              <div className={classes.searchContainer}>
              <SearchBar
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    
                      padding: "10px", // Adjust padding to reduce height
                      width: "300px",    // Set a specific width
                      fontSize: "14px",  // Adjust font size for better proportions
                      boxSizing: "border-box", // Ensure consistent sizing
                      borderRadius: "4px", // Add slight rounding for aesthetics
                      
                  }}
              />
              {searchTerm !== "" && <div>
                  <p>{filteredData.length} results</p>
              </div>}
            </div>

              <DevicesList
                devices={filteredData}
                filtering={""}
                hideControls={false}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                pageFetchedIndex={pageFetchedIndex}
                setPageFetchedIndex={setPageFetchedIndex}
                pageSize={filteredData.length}
                setPageSize={setPageSize}
                totalDevices={filteredData.length}
                setTotalDevices={setTotalDevices}
                groupMode={false}
                update={update}
                setUpdate={setUpdate}
                offlineOnly={true}
                isSortable={true}
              />
              </>
              
            )}
          </div>
            </>
          )

          

        }}
      </Await>
    )
  }


export default OfflineSites;