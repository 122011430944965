import React, { useState, useMemo, useRef, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeUsersRole, removeUserFromDeviceManagement, getDeviceEvents } from "../../../store/service-actions";
import { getDeviceUsers } from "../../../store/service-actions";
import classes from './SiteView.module.css';
import AppView from "../../UI/Pin/AppView/AppView";
import ContorlsBar from "../../UI/ControlsBar/ControlsBarV2/ControlsBarV2";
import Logs from "../../sub-components/Logs/Logs";
import { defer, useLoaderData } from "react-router-dom";
import { serviceActions } from "../../../store/service-Slice";
import GroupsGrid from "../../GridList/GroupsGrid";
import { getDeviceEventLogsRanged, getSingleDeviceData, getSingleDeviceUsers, changeRole, removeUser, inviteUser, getInputRules, getSchedules, getCamectsAPI, getHolidaysModesAPI, getSigFoxSensorsAPI, suspendDevice, clearDeviceLogs, getGroups, addSitesToGroup, changeCamectIntegration, rebootIPIODevice, resetIPIODevice } from "../../../Api Methods/Api";
import Modal from "../../Modal/Modal";
import HandleResponseData from "../../../hooks/HandleResponseData";
import SingleDeviceUsersTableLayout from "../../UI/Table/SingleDeviceUsersTableLayout";
import { Await, useNavigate } from 'react-router'
import { Oval } from "react-loading-icons";
import Automations from "../../UI/Cards/Automations/Automations";
import VirtualSources from "../../UI/Cards/Automations/VirutalSources/VirtualSources";
import { useSocketHook } from "../../../Socket/useSocketHook";
import parsePinStates from "../../../Socket/parsePinStates";
import { authActions } from "../../../store/auth-Slice";
import DropdownList from "../../UI/Cards/DropdownList";
import Dropdown from '../../../components/forms/DropdownCreateGroup'
import SuspendIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/suspend.png'
import ClearLogsIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/clearLogs.png'
import ChangePortalNameIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/changeTag.png'
import AddToGroupIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/addToGroup.png'
import CamectIntegrationIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/camectIntegration.png'
import CopyConfigIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/copyConfig.png'
import RebootIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/reboot.png'
import ResetIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropdownIcons/reset.png'
import settingsIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__Admin - Settings.svg'
import DropDownForm from "../../forms/DropDownForm";
import check from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'
import fail from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/cross.png'
import Button1 from "../../UI/Button/Button1";
import SetTag from "../../Modal/ModalLayouts/SetTag";
import DataManager from "../../../hooks/DataManager";



const SiteView = () => {
    const { SiteData, SiteEventLogs, SiteDeviceUserList } = useLoaderData();

    useEffect(() => {
        if (SiteData.isError) {
            return SiteData.message;
        }
        if (SiteDeviceUserList.isError) {
        }

    }, [SiteData, SiteEventLogs, SiteDeviceUserList])

    const dispatch = useDispatch();
    const sesstionDeviceUUID = sessionStorage.getItem("DeviceUUID")
    dispatch(authActions.updateDeviceUUID(sesstionDeviceUUID));
    const [parsedSiteData, setParsedSiteData] = useState(SiteData);
    const [hasDeviceChanged, setHasDeviceChanged] = useState();
    const [siteData, setSiteData] =  useState(HandleResponseData(parsedSiteData))
    console.log(siteData)
    const dataManager = new DataManager();
    const firmware = siteData[1].firmware
    const firmwareMajor = firmware[0]
    const firmwareMinor = firmware[1]
    const DeviceType = siteData[1].type;
    const isOnline = siteData[1].online == "YES" ? true : false;
    const status = siteData[1].status
    const camectIntegationType = siteData[1].camectIntegrationType;
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);
    const userRole = useSelector(state => state.auth.role);
    const companyName = useSelector(state => state.auth.companyName);
    const deviceId = siteData[1].id;
    const deviceTag = siteData[1].tag;
    const uipioid = siteData[1].key;
    const userEmail = useSelector(state => state.auth.email);
    const [deviceData, setDeviceData] = useState(siteData[0]);
    const [deviceEventsList, setDeviceEventsList] = useState(SiteEventLogs);
    const [deviceUserList, setDeviceUsersList] = useState(SiteDeviceUserList);
    const apiData = {token, client}
    const CamectIntegationType = useSelector(state => state.service.CamectIntegationType);
    const [activeTable, setActiveTable] = useState("Events");
    const DeviceId = siteData[1].id
    const DeviceUUID = siteData[1].uuid;
    const DeviceEvents = useSelector(state => state.service.singleDeviceEvents);
    const [refreshTable, setRefreshTable] = useState(false);
    const [modalTitle, setModalTitle] = useState("title")
    const [modalComponent, setModalComponent] = useState()
    const ModalDialog = useRef();
    const [prevTableID, setPrevTableID] = useState("Events")
    const [update, setUpdate] = useState(0)
    const [selectedGroup, setSelectedGroup] = useState()
    const [groups, setGroups] = useState([]);
    const [pinEdited, setPinEdited] = useState(false)

    const GetGroups = async () => {
        try {
            const listOptions = await getGroups(apiData, dispatch);
            console.log(listOptions);
            setGroups(listOptions || []);
        } catch (error) {
            console.error('Failed to fetch groups:', error);
            setGroups([]);
        }
    };

    // const updateSiteData = async () => {
    //     const response = await getSingleDeviceData(apiData, DeviceUUID)
    //     setSiteData(HandleResponseData(response))
    // }

    useEffect(() => {
        if (pinEdited){
            window.location.reload();
        }
        
    }, [pinEdited])

    useEffect(() => {
        GetGroups();
    }, []);

    useEffect(() => {
        const HandleDeviceData = async () => {
            if (hasDeviceChanged) {
                console.log("update")
                const SingleDeviceData = await getSingleDeviceData(apiData, DeviceUUID);
                setParsedSiteData(SingleDeviceData);
            }
        }
        HandleDeviceData();
    }, [hasDeviceChanged]);


    //new use Effect to update after editing:
    //Then try this to update:
    //dispatch(serviceActions.GetSingleDeviceData(DeviceUUID, apiData))



    //Get array of pinNames
    const OutPutsPinNames = siteData[0].OutputPins.map((Output) => {
        const { pinName, Pin, pinStatusOn, pinStatusOff, type, ...rest } = Output;
        return Output
    })

    // check if device has no inputs if not then skip
    let InputsPinNames;
    if (siteData[1].numInput != 0) {
        InputsPinNames = siteData[0].InputPins.map((Input) => {
            const { pinName, Pin, pinStatusOn, pinStatusOff, ...rest } = Input;
            return { Pin, pinName, pinStatusOn, pinStatusOff }
        })
    }

    let DeviceName = siteData[1].deviceName != "" ? siteData[1].deviceName : siteData[1].tag
    if (DeviceName == "" || DeviceName == null) {
        DeviceName = uipioid
    }

    console.log(siteData[1].deviceName)
    console.log(siteData[1].tag)
    console.log(DeviceName)

    const AddSiteToGroup = async (uuid) => {
        console.log(dataManager.getItem('selectedGroup'))
        const group = groups.find(g => g.name === dataManager.getItem('selectedGroup'));
        console.log(dataManager.getItem('selectedGroup'))
        
            let body = {
                groupID: group.id,
                groupName: group.name,
                groupDevices: [uuid]
            }

            const response = await addSitesToGroup(apiData, body, dispatch);
            console.log(response)
            if (response.success) {
                HandleCloseModal();
                successModal("Site successfully added to group: " + dataManager.getItem('selectedGroup'), false);
                //dispatch(GetSingleDeviceData(DeviceUUID, apiData));
                siteData[1].name = group.name
                dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
            } else {
                errorModal("Failed to add site to group, please try again", false);
            }
          
    }


    const resetSite = async (apiData, DeviceUUID, userID, DeviceName, dispatch) => {
        const response = await resetIPIODevice(apiData, DeviceUUID, userID, DeviceName, dispatch)
        if (response.success) {
            successModal("Site reset successfully!", false)
            dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
        }
    }   

    

    sessionStorage.setItem('DeviceID', deviceId);
    sessionStorage.setItem('DeviceName', DeviceName);

    const successModal = (message) => {
        setModalTitle('Success')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.Icon} src={check}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
            </div>   
            <br></br>
            <br></br>
            <div>
              <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
            </div>
          </div>
        )
        HandleShowModal();
    }

    const HandleReloadData = () => {
        HandleCloseModal()
        window.location.reload();
    }

    const camectIntegrationChangedModal = (message) => {
        setModalTitle('Success')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.Icon} src={check}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
                <br></br>
                <p style={{ textAlign: "center", color:"#858585"}}>Page will have to reload for changes to take effect</p>
            </div>   
            <br></br>
            <br></br>
            <div>
              <Button1 onClick={HandleReloadData} style={{width:"100%"}}>Reload</Button1>
            </div>
          </div>
        )
        HandleShowModal();
    } 
    
    const errorModal = (message, isCopyConfig) => {
    setModalTitle('Error')
    setModalComponent(
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img className={classes.Icon} src={fail}>
        </img>
        <br></br>
        <br></br>
        <div>
            <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
        </div>   
        <br></br>
        <br></br>
        <div>
            
            <Button1 style={{width:"100%"}} onClick={HandleCloseModal}>Cancel</Button1>
        </div>
        </div>
    )
    HandleShowModal();
    }

    const handleItemClick = async (item) => {
        switch(item.title){
            case 'Unsuspend site':
            case 'Suspend site':
                console.log(siteData[1].status)
                const suspend = siteData[1].status == "suspended" ? "available" : "suspended";
                const response = await suspendDevice(apiData, suspend, DeviceId, dispatch);
                if (response) {
                    
                    HandleCloseModal();
                    let status;

                    if (siteData[1].status === "suspended") {
                        status = "available"
                    } else {
                        status = "suspended"
                    }
                    successModal("Site is now " + status)
                    siteData[1].status = suspend
                    dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
                  } else {
                    errorModal("Something went wrong, please try again", false)
                  }
            break;
            case 'Clear logs':
                const HandleClearLogs = async () => {
                    const response = await clearDeviceLogs(apiData, DeviceUUID, userID, dispatch);
                    if (response.success) {
                        HandleCloseModal();
                        
                        successModal("Logs cleared successfully", false)
                    } else {
                        errorModal("Something went wrong, please try again", false)
                    }
                }

                HandleCloseModal(); //Close the current settings modal
                setModalTitle("Clear Logs");
                setModalComponent(
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
                    <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
                        <h4 style={{ margin: "0px" }}>{"Are you sure you want to clear device: " + uipioid + " logs? This action is "}</h4>
                        <h4 style={{ margin: "0px", color: "red" }}>permanent</h4>
                        <h4 style={{ margin: "0px" }}>!</h4>
                    </div>
                    <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
                        <Button1 propsclassName={classes.CancelButton} onClick={HandleCloseModal}>Cancel</Button1>
                        <Button1 propsclassName={classes.ClearLogs} onClick={() => HandleClearLogs()}>Clear Logs</Button1>
                    </div>
                    </div>
                )
                HandleShowModal()
            break;
            case 'Change portal name':
                HandleCloseModal();
                const ChangeTag = () => {
                    setModalTitle("Change portal name")
                    setModalComponent(
                    <SetTag 
                        placeholder={siteData[1].tag} 
                        DeviceUUID={DeviceUUID} 
                        HandleCloseModal={HandleCloseModal} 
                        dispatch={dispatch} 
                        update={update} 
                        setUpdate={setUpdate} 
                        siteData={siteData}
                        setSiteData={setSiteData}
                        successModal={successModal}
                        errorModal={errorModal}
                    />)
                    HandleShowModal()
                }
                ChangeTag();
            break;
            case 'Add to group':
                const ShowGroupsModal = async () => {
                  console.log(groups)
                  
                  const groupNames = groups.map(listOptions => listOptions.name);
                  HandleCloseModal()
                  setModalTitle("Add site to group")
                  setModalComponent(
                    <div>
                      <Dropdown label={"Please select a group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
                      <br></br>
                      <Button1 onClick={() => AddSiteToGroup(DeviceUUID)}>Add to group</Button1>
                    </div>
                    
                  )
                  HandleShowModal();
                }
        
                ShowGroupsModal();
            break;
            case 'Change camect integration type':
                const Changecamectintergrationtype = async () => {
                    const CamectIntegationType = siteData[1].camectIntegrationType == 2 ? 1 : 2;
                    const notificationMessage = CamectIntegationType == 1 ? "Whole Camect" : "Per Cam Arming"
                    const response = await changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, DeviceName, dispatch)
                    console.log(response)
                    if (response) {
                        camectIntegrationChangedModal("Camect integration changed to " + notificationMessage)
                        siteData[1].camectIntegrationType = CamectIntegationType
                        dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
                    } else {
                        errorModal("Failed to change camect integration type, please try again")
                    }
                }
                
                Changecamectintergrationtype();
            break;
            case 'Reset site':
                const ResetSite = () => {
                    HandleCloseModal()
                    setModalTitle("Are you sure?")
                    setModalComponent(
                        <div>
                            <div style={{ textAlign: "center" }}>
                            <h2>Are you sure you want to reset: {uipioid}</h2>
                            </div>
                            <p style={{ textAlign: "center" }}>This will revert all settings & customisations to factory default.</p>
                            <p style={{ textAlign: "center" }}>This action cannot be undone!</p>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px" // Adds space between buttons
                            }}>
                                <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
                                <Button1 onClick={() => resetSite(apiData, DeviceUUID, userID, DeviceName, dispatch)}>Yes, I'm sure</Button1>
                            </div>
                        </div>
                    )

                    HandleShowModal();
                } 
                
                ResetSite();    
            break;
            case 'Reboot site':
                const RebootDevice = async () => {
                    const response = await rebootIPIODevice(apiData, DeviceUUID, userID, DeviceName);
                    if (response){
                        successModal("Site rebooting...")
                    } else {
                        errorModal("Failed to reboot site, please try again")
                    }
                }

                RebootDevice();
            break;
            default:
            break;
        }
      };
    
    const tableNameHandler = (tableId) => {
        console.log(tableId)
        
        if (tableId !== "Settings"){
            setPrevTableID(tableId)
            setActiveTable(tableId);
        } else {

            let items = [
                {
                  image: SuspendIcon,
                  title:
                    siteData[1].status !== "suspended"
                      ? "Suspend site"
                      : "Unsuspend site",
                },
                {
                  image: ClearLogsIcon,
                  title: "Clear logs",
                },
                {
                  image: ChangePortalNameIcon,
                  title: "Change portal name",
                },
                {
                  image: AddToGroupIcon,
                  title: "Add to group"
                }
            ];
        
            if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
            items.splice(4, 0, {
                image: CamectIntegrationIcon,
                title: "Change camect integration type",
            },);
            }
        
            
        
            if (isOnline && DeviceType != "CC") {
                items.splice(5, 0, {
                image: RebootIcon,
                title: "Reboot site",
                })
            }
        
            if (isOnline){
            items.splice(6, 0,
                {
                image: ResetIcon,
                title: "Reset site",
                });
            }


            setModalTitle("Site settings")
            setModalComponent(
                <ul style={{ listStyleType: "none", padding: 0 }}>
                {items.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleItemClick(item)} // Pass the clicked item to the handler
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{ width: "24px", height: "24px", marginRight: "10px" }}
                    />
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
            )
            HandleShowModal();
        }
    }

    const HandleDeviceUserList = async () => {
        const data = await getSingleDeviceUsers(apiData, DeviceUUID, dispatch)
        setDeviceUsersList(data)
    }

    useEffect(() => {
        console.log("Site data modified")
        console.log(siteData)
        dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
    }, [siteData])

    const HandleRefreshTable = () => {
        setRefreshTable(true);
    }


    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        setModalComponent(<></>)
        tableNameHandler(prevTableID)
        setHasDeviceChanged(true)

    }

    const iglooLocks = siteData[0].singleDeviceSettingData.iglooLocks
    const renderTable = (tableId) => {
        switch (tableId) {
            case 'Events':
                return (
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "100%", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={SiteEventLogs}>
                            {(loadedDeviceEventsList) =>
                                <Logs SiteEventLogs={loadedDeviceEventsList} apiData={apiData} />
                            }
                        </Await>
                    </Suspense>
                )
            case 'Users':
                return (
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "100%", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={deviceUserList}>
                            {(loadedDeviceUserList) =>
                                <SingleDeviceUsersTableLayout SiteDeviceUserList={loadedDeviceUserList} setModalTitle={setModalTitle} setModalComponent={setModalComponent} deviceTag={deviceTag} apiData={apiData} deviceId={deviceId} userID={userID} userRole={userRole} userEmail={userEmail} DeviceName={DeviceName} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} DeviceUUID={DeviceUUID} companyName={companyName} HandleDeviceUserList={HandleDeviceUserList} />
                            }
                        </Await>
                    </Suspense>
                )
            case 'Automations':
                return (
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "100%", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={siteData[0]}>
                            {(loadedSiteData) =>
                                <Automations OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} deviceData={loadedSiteData} />
                            }
                        </Await>
                    </Suspense>
                )
            case 'Groups':
                return <GroupsGrid />
            case 'Devices':
                return (
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "100%", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={iglooLocks}>
                            {(LoadedIglooLocks) =>
                                <VirtualSources iglooLocks={LoadedIglooLocks} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} deviceData={siteData[0]} setHasDeviceChanged={setHasDeviceChanged} DeviceDetails={siteData[1]} />
                            }
                        </Await>
                    </Suspense>
                )
            default:
                return null;
        }
    }

    const deviceUUID = sessionStorage.getItem("DeviceUUID");
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Function to check window size
    const checkWindowSize = () => {
        // Set your breakpoint (e.g., 768px)
        if (window.innerWidth < 1482) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    // Add event listener on mount
    useEffect(() => {
        checkWindowSize(); // Check size on mount
        window.addEventListener('resize', checkWindowSize); // Add event listener

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []); // Empty array ensures this runs only once on mount


    return (
        <div style={{ height: "100%", width: "100%", paddingBottom: "100px" }}>
            <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal}>
                {modalComponent}
            </Modal>
            <div className={classes.siteView}>
                <div className={classes.mainView}>
                    <Suspense fallback={
                        <div className={classes["loadingSpinner-container"]}>
                            <div style={{ width: "100%", height: "100%", background: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "12px", gap: "15px" }}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>
                        </div>
                    }>
                        <Await resolve={siteData}>
                            {(loadedSiteData) =>
                                <AppView singleDevicePins={loadedSiteData[0]} deviceUUID={deviceUUID} setPinEdited={setPinEdited} firmware={firmware} setHasDeviceChanged={setHasDeviceChanged} />
                            }
                        </Await>
                    </Suspense>

                    
                    {<div className={classes.right}>
                        <ContorlsBar activeTable={activeTable} tableNameHandler={tableNameHandler} HandleRefreshTable={HandleRefreshTable} />
                        {activeTable && renderTable(activeTable)}
                        
                    </div>}
                    
                </div>
            </div>


        </div>
    )
}

export default SiteView;

export async function loader(params) {
    // loader is a javascript function only, so gets data and header using session storage.
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const DeviceUUID = params.params.SiteID
    // Storing Device UUID to session storage for refresh purposes.
    sessionStorage.setItem('DeviceUUID', DeviceUUID);

    const todayDate = new Date();
    const todayDateString = todayDate.toISOString();

    const thirtyDaysAgoDate = new Date()
    thirtyDaysAgoDate.setDate(thirtyDaysAgoDate.getDate() - 30);
    const thirtyDaysAgoDateString = thirtyDaysAgoDate.toISOString();

    const apiData = {
        token,
        client
    }

    return defer({
        SiteData: await getSingleDeviceData(apiData, DeviceUUID),
        SiteDeviceUserList: getSingleDeviceUsers(apiData, DeviceUUID),
        SiteEventLogs: getDeviceEventLogsRanged(apiData, DeviceUUID, thirtyDaysAgoDateString, todayDateString),
    })
}

