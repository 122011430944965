import classes from './App.module.css';
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./store/auth-Slice";
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import RootLayout from "./Pages/Root/Root/AuthRootLayout";
import SubRootLayout from './Pages/Root/SubRoot/SubRootLayout';
import SearchView, { loader, loader as SearchViewLoader } from "./Pages/SearchView/SearchView";
import AllSites, { loader as DashboardLoader } from './Pages/AllSites/allSites';
import Dashboard from './Pages/Dashboard/Dashboard'
import SiteView, { loader as siteviewLoader } from "./components/Site/Device/SiteView";
import ErrorPage from "./Pages/Error/ErrorPage";
import VerifyPage from "./components/LoggingIn/VerifyAccount/VerifyPage";
import LoginPage from './components/LoggingIn/LoginV2/LoginPage';
import PortalActivityPage, { loader as portalActivityLoader } from './Pages/PortalActivity/PortalActivityPage';
import UserManagementPage, { loader as userManagementPageLoader } from './Pages/User Management/UserManagementPageTableView';
import { uiActions } from './store/ui-slice';
import UserDevicesPage, { loader as userDevicesPageLoader } from './Pages/User Devices/UserDevicesPage';
import UserManagementRoot from './Pages/User Management/UserManagementRoot';
import RegisterPage from './components/LoggingIn/Register/RegisterPage';
import FullRegistration from './components/UI/Cards/FormCard/FullRegistration';
import Padlocks from './Pages/Padlocks/padlocks';
import Sensors from './Pages/Sensors/sensors';
import Keyboxes from './Pages/Keyboxes/keyboxes';
import OfflineSites from './Pages/OfflineSites/offlineSites';
import Controllers from './Pages/Controllers/Controllers';
import UserManagementPageCardGrid from './Pages/User Management/UserManagementPageCardGrid';
import { tokenLoader, checkAuthLoader } from "./auth";
import { loader as logoutAction } from "./Pages/Logout/Logout";
import DeviceActivityPage, {loader as deviceActivityPageLoader} from './Pages/DeviceActivity/DeviceActivityPage';
import Groups, {loader as groupsLoader} from './Pages/Groups/Groups';
import GroupSites, {loader as GroupSitesLoader} from './Pages/Groups/GroupSites';
import GroupUsers from './Pages/Groups/GroupUsers';
import { socket } from './Socket/socket';
import DataManager from './hooks/DataManager';
import { elements } from 'chart.js';

const dataManager = new DataManager();

const AuthRoute = {

  path: '/',
  element: <RootLayout/>,
  errorElement: <ErrorPage/>,
  loader: tokenLoader,
  children: [
    {
      index: true,
      element: <LoginPage/>
    },
    {
      path: 'Verify',
      element: <VerifyPage/>,
      loader: checkAuthLoader,
    },
    {
      path: 'Register',
      element: <RegisterPage />
    },
    {
      path: 'FullRegistration',
      element: <FullRegistration />
    },
    {
      path: '/AllSites',
      element: <SubRootLayout />,
      loader: checkAuthLoader,
      children: [
        {
          index: true,
          element: <AllSites />,
          loader: DashboardLoader
        },
        {
          path: 'SearchView',
          element: <SearchView />,
          loader: SearchViewLoader
        },
        {
          path: 'SiteView/:SiteID',
          element: <SiteView />,
          loader: siteviewLoader
        },
        {
          path: 'Users',
          element: <UserManagementRoot />,
          children: [
            {
              index: true,
              element: <UserManagementPageCardGrid />,
              loader: userManagementPageLoader
            },
            {
              path: 'UserSites',
              element: <UserDevicesPage />,
              loader: userDevicesPageLoader
            },
          ]
        },
        {
          path: 'Dashboard',
          index: true,
          element: <Dashboard/>
        },
        {
          path: 'Offline',
          element: <OfflineSites/>
        },
        {
          path: 'Controllers',
          element: <Controllers/>
        },
        {
          path: 'Activity',
          element: <PortalActivityPage />,
          loader: portalActivityLoader
        },
        {
          path: 'SiteActivity',
          element: <DeviceActivityPage />,
          loader: deviceActivityPageLoader
        },
        {
          path: 'Groups',
          element: <UserManagementRoot />,
          children: [
            {
              index: true,
              element: <Groups />,
              loader: groupsLoader,
            },
            {
              path: 'GroupSites',
              element: <GroupSites />,
              children: [
                {
                  path: 'GroupUsers',
                  element: <GroupUsers />,
                },
              ],
            },
          ],
        },
        {
          path: 'Padlocks',
          element: <Padlocks />
        },
        {
          path: 'Keyboxes',
          element: <Keyboxes />
        },
        {
          path: 'Sensors',
          element: <Sensors />
        },
      ]
    },
    {
      path: '/logout',
      loader: logoutAction
    }
  ],
}

const router = createBrowserRouter([
  AuthRoute
]);

const App = (props) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [pinStates, setPinStates] = useState([]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onPinStatesChange(value) {
      if (dataManager.getItem('deviceUUIDs').includes(value['uuid'])){
        setPinStates(value);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('appPinStates', onPinStatesChange);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('appPinStates', onPinStatesChange);
    };
  }, []);


  const token = useSelector(state => state.auth.token);
  const refreshToken = useSelector(state => state.auth.refreshToken);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <RouterProvider router={router} />

    </React.Fragment >
  );
}

export default App;


