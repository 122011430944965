import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import classes from './InviteUserForm.module.css';
import Button1 from "../UI/Button/Button1";
import { addGroup, changeDeviceOwner, changeDropDown } from "../../Api Methods/Api";
import { uiActions } from "../../store/ui-slice";
import { useDispatch } from "react-redux";
import DataManager from "../../hooks/DataManager";

const dropDownReducer = (state, action) => {

    if (action.type === 'TOGGLE_DROPDOWN') {
        return { ...state, showDropDownOptions: !state.showDropDownOptions, isValid: null };
    }

    if (action.type === 'SELECT_OPTION') {
        return { ...state, selectedOption: action.payload, showDropDownOptions: false, isValid: action.val != "DropDown" };
    }

    if (action.type === 'BLUR_DROPDOWN') {
        return { ...state, showDropDownOptions: false, isValid: action.val != "DropDown" };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { selectedOption: null, isValid: null };
    }
    //otherwise 
    return { state };
};

const DropDownForm = ({ label, dropDownOptions, setSelectedGroup }) => {

    const dataManager = new DataManager();

    const [dropDownState, dispatchDropDown] = useReducer(dropDownReducer, {
        showDropDownOptions: false,
        selectedOption: '',
        isValid: null
    });

    const { showDropDownOptions: showDropDownDropDownOptions, selectedOption: selectedDropDownOption } = dropDownState;

    const dropDownDropdownRef = useRef();

    const handleDropDownDropDownOnClick = () => {
        dispatchDropDown({ type: 'TOGGLE_DROPDOWN' });
    };

    const handleDropDownOptionChange = (option) => {
        dispatchDropDown({ type: 'SELECT_OPTION', payload: option });
        setSelectedGroup(option); 
        dataManager.setItem('selectedGroup', option);
    };

    const handleDropDownBlur = () => {
        dispatchDropDown({ type: 'BLUR_DROPDOWN' });
    };

    return (
        <ul className={classes["inputs-list"]}>
            <div className={classes.label}>{label}</div>
            <li className={classes["input-container"]}>
                <CustomDropDown
                    options={dropDownOptions}
                    selectedOption={selectedDropDownOption}
                    handleOptionChange={handleDropDownOptionChange}
                    handleBlur={handleDropDownBlur}
                    handleDropDownOnClick={handleDropDownDropDownOnClick}
                    showDropDownOptions={showDropDownDropDownOptions}
                    isValid={true}
                    ref={dropDownDropdownRef}
                />
            </li>
        </ul>
    )
}

export default DropDownForm;

