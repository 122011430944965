import { useDispatch, useSelector } from "react-redux";
import { getSensors } from "../../Api Methods/Api";
import { useEffect, useState } from "react";
import { Await } from "react-router-dom";
import PadlocksList from "../Padlocks/padlocksList";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import Stats2 from "../../components/UI/Stats/Stats2";

const Sensors = () => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }

    const [sensors, setSensors] = useState([])
    const [sensorCount, setSensorCount] = useState();
    const [update, setUpdate] = useState(0)
    const filtering = ""
    const [isLoading, setIsLoading] = useState(true)

    //Pull data on mount
    useEffect(() => {
        const HandleGetSensors = async () => {
            const response = await getSensors(apiData, dispatch);
            setSensors(response.data[0])
            setIsLoading(false)
        }

        HandleGetSensors();
    }, [update])


    return (
        <Await resolve={sensors}>
                <div style={{ width: "100%" }}>
                    <Stats2 title={"Total sensors"} value={sensors.length}  isLoadingStats={isLoading} isStatic={true} />
                    <br></br>
                    {isLoading ? (
                        <div style={{ display: "flex", width: "100%" }}>
                            <DeviceLoadingGrid
                                CardCount={20}
                            />
                        </div>
                        ) : (
                            <PadlocksList 
                                locks={sensors}
                                filtering={filtering}
                                update={update}
                                setUpdate={setUpdate}
                                isSensors={true}
                            />
                        )
                    }
                </div>
           
        </Await>
    );
}


export default Sensors;