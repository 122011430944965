import classes from './PageContent.module.css';

import img from "../../assets/EyeSpyFX.png"
import brokenPage from "../../assets/broken-page.png"
import Tooltip from '../../components/ToolTip/ToolTip';
import Button1 from '../../components/UI/Button/Button1';
import { useState } from 'react';
import knowioLogo from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/knowioLogo.png'
import Button2 from '../../components/UI/Button/Button2';
import Button from '../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';

function PageContent({ errorStatus, title, errorMessage }) {

  const navigate = useNavigate();

  const HandleNavigateBack = () => {
    navigate("/AllSites")
  }


  const [showError, setShowError] = useState(false)
  return (
    <div className={classes.content}>
      <div className={classes.errorBox}>
        <div className={classes.left}>
          <div className={classes.logoTextContainer}>
            <img className={classes.logo} src={knowioLogo}/>
            <br></br>
          </div>
          <div className={classes.errorContainer}>
            <div style={{ display: "flex", gap: "4px" }}>
              <h3 style={{ fontWeight: "800" }} className={classes.text}>{errorStatus}</h3>
              <h3 style={{ fontWeight: "400" }} className={classes.text} >{title}</h3>
            </div>
            <p className={classes.text} style={{ width: "400px" }}>We have logged the error and will resolve it ASAP. Thank you for your patience.</p>

            {/* <Button1 style={{ minWidth: "94px" }} onClick={() => setShowError(prevShowError => !prevShowError)}>{showError ? "Hide Error" : "Show Error"}</Button1> */}

            {/* {showError && <p className={classes.text} style={{ width: "400px" }}>{errorMessage}</p>} */}
            <div style={{ display: "flex", flexDirection: "column", marginTop:"10px"}}>
              <Button1 onClick={HandleNavigateBack}>Take me back</Button1>
              {/* <p className={classes.text} style={{ width: "400px", margin: "0px" }}>Error:</p>
              <p className={classes.text} style={{ width: "400px", margin: "0px" }}>{errorMessage}</p> */}
            </div>
          </div>
        </div>

        <Tooltip>
          <img src={brokenPage} className={classes.errorImage} />
        </Tooltip>

      </div>

    </div >
  );
}

export default PageContent;