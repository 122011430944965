import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getPagedDevicesAPI } from "../../Api Methods/Api";
import { Await, useNavigate } from "react-router-dom";
import DevicesList from "../../components/GridList/DevicesList";
import DataManager from "../../hooks/DataManager";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import classes from '../OfflineSites/offlineSites.module.css'
import Stats2 from "../../components/UI/Stats/Stats2";
import SearchBar from "../SearchView/Search/SearchBar";
import { filter } from "rxjs";

const Controllers = () => {

    const dataManager = new DataManager();
    const [sites, setSites] = useState([])
    const [offlineSites, setOfflineSites] = useState([])
    const [onlineSites, setOnlinesites] = useState([])
    const [sitesNoUsers, setsitesNoUsers] = useState([])
    const [totalPages, setTotalPages] = useState([])
    const [totalDevices, setTotalDevices] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [update, setUpdate] = useState(0)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);

    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]); // Track filtered data

    const [pageFetchedIndex, setPageFetchedIndex] = useState(1)
    const [pageSize, setPageSize] = useState(1000)

    const apiData = {
        token,
        client
    }

    useEffect(() => {
        const getSites = async () => {
          const responseData = await getPagedDevicesAPI(apiData, 5000, pageFetchedIndex, 1, 1, 0, 0, dispatch);
          const totalDevices = responseData[0].totalDevices["COUNT(*)"];
          const data = responseData[0].devices;
      
          const allSites = [];
          const offlineSites = [];
          const onlineSites = [];
          const sitesNoUsers = [];
      
          data.forEach((device) => {
            if (["AB", "ESP", "A"].includes(device.type)) {
              allSites.push(device);
              if (device.online === "NO") offlineSites.push(device);
              if (device.online === "YES") onlineSites.push(device);
              if (device.userCount === 0) sitesNoUsers.push(device);
            }
          });
      
          setFilteredData(allSites);
          setSites(allSites);
          setOfflineSites(offlineSites);
          setOnlinesites(onlineSites);
          setsitesNoUsers(sitesNoUsers);
      
          setTotalPages(Math.ceil(totalDevices / pageSize));
          setTotalDevices(totalDevices);
          setPageSize(totalDevices);
          setIsLoading(false);
        };
      
        getSites();
      }, []);

    const applyFilter = (filterData) => {
      setFilteredData(filterData); // Update filteredData based on clicked filter
    };

    useEffect(() => {
      const handleDeviceFilter = () => {
        const filtered = sites.filter((item) =>
          Object.values(item)
            .some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        );
        dataManager.setItem('controllersSearchTerm', searchTerm); // Store search term
        setFilteredData(filtered); // Update filtered data
      };
    
      if (searchTerm !== dataManager.getItem('controllersSearchTerm')) {
        handleDeviceFilter(); // Call filtering function
      }
    }, [searchTerm, sites]);
    


    
    return (
      <Await resolve={sites}>
        {(loadedSites) => {
          return (
            <>
            <div style={{ width: "100%"}}>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <div
                onClick={() => {
                 applyFilter(loadedSites); 
                }}
                style={{ cursor: "pointer", width:"350px"}} 
              >
              <Stats2
                title={"All controller count"}
                value={loadedSites.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
            <div
                onClick={() => {
                  applyFilter(onlineSites) 
                }}
                style={{ cursor: "pointer", width:"350px" }} 
              >
              <Stats2
                title={"Online controllers"}
                value={onlineSites.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
            <div
                onClick={() => {
                  applyFilter(offlineSites) 
                }}
                style={{ cursor: "pointer", width:"350px" }} 
              >
              <Stats2
                title={"Offline controllers"}
                value={offlineSites.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
            <div
                onClick={() => {
                  applyFilter(sitesNoUsers) 
                }}
                style={{ cursor: "pointer", width:"350px" }} 
              >
              <Stats2
                title={"Controllers with no users"}
                value={sitesNoUsers.length}
                isLoadingStats={isLoading}
                isStatic={true}
              />
            </div>
          </div>

            <br></br>
            {isLoading ? (
              <div style={{ display: "flex", width: "100%" }}>
                <DeviceLoadingGrid
                  CardCount={
                    20
                  }
                />
              </div>
            ) : (
              <>
              <div className={classes.searchContainer}>
              <SearchBar
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    
                      padding: "10px", // Adjust padding to reduce height
                      width: "300px",    // Set a specific width
                      fontSize: "14px",  // Adjust font size for better proportions
                      boxSizing: "border-box", // Ensure consistent sizing
                      borderRadius: "4px", // Add slight rounding for aesthetics
                      
                  }}
              />
              {searchTerm !== "" && <div>
                  <p>{filteredData.length} results</p>
              </div>}
            </div>

              <DevicesList
                devices={filteredData}
                filtering={""}
                hideControls={false}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                pageFetchedIndex={pageFetchedIndex}
                setPageFetchedIndex={setPageFetchedIndex}
                pageSize={filteredData.length}
                setPageSize={setPageSize}
                totalDevices={filteredData.length}
                setTotalDevices={setTotalDevices}
                groupMode={false}
                update={update}
                setUpdate={setUpdate}
                offlineOnly={false}
                isSortable={true}
              />
              </>
              
            )}
          </div>
            </>
          )

          

        }}
      </Await>
    )
  }


export default Controllers;