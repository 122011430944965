import { useEffect, useState } from "react";
import { getDashboardStats, getGroupStats } from "../../Api Methods/Api";
import { useDispatch, useSelector } from "react-redux";
import { Await } from "react-router-dom";
import Stats2 from "../../components/UI/Stats/Stats2";
import classes from './Dashboard.module.css'

const Dashboard = () => {
    const [stats, setStats] = useState();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const HandleGetStats = async () => {
            const response = await getDashboardStats(apiData, dispatch);
            setStats(response.data[0][0]);
            setIsLoading(false)
        }

        HandleGetStats()
    }, []);

    return (
        <div className={classes.pageContainer}>
            <Await resolve={stats}>
            {(loadedStats) => (
                   <div className={classes.container}>
                        {stats && <Stats2 title={"Online sites"} value={(loadedStats.sitesCount - loadedStats.offlineCount)} isLoadingStats={isLoading} isStatic={true}/>}
                        {stats && <Stats2 title={"All sites"} value={loadedStats.sitesCount} isLoadingStats={isLoading} isStatic={true} />}
                        {stats && <Stats2 title={"Controllers"} value={loadedStats.controllerCount} isLoadingStats={isLoading} isStatic={true}/> }
                        {stats && <Stats2 title={"Offline controllers"} value={loadedStats.offlineCount} isLoadingStats={isLoading} isStatic={true}/> } 
                        {/* {stats && <Stats2 title={"Empty sites"} value={loadedStats.accountsCount} isLoadingStats={isLoading} isStatic={true}/> } */}
                        {stats && <Stats2 title={"Users"} value={loadedStats.totalUserCount} isLoadingStats={isLoading} isStatic={true}/> }
                        {stats && <Stats2 title={"Groups"} value={loadedStats.accountsCount} isLoadingStats={isLoading} isStatic={true}/> }
                        {stats && <Stats2 title={"Padlocks"} value={loadedStats.lockCount} isLoadingStats={isLoading} isStatic={true}/> }
                        {stats && <Stats2 title={"Keyboxes"} value={loadedStats.keyboxCount} isLoadingStats={isLoading} isStatic={true}/> }
                        {stats && <Stats2 title={"Sensors"} value={loadedStats.sensorCount} isLoadingStats={isLoading} isStatic={true}/> }
                   </div>
                )}
            </Await>
        </div>
    )
}

export default Dashboard;