import React, { Suspense, useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UserCardContentLayout from '../../components/UI/DataLayouts/UserCardContentLayout';
import GridPaginationControls from '../../components/Table/Table/GridPaginationControls';
import Card from './Card';
import classes from './GroupUsers.module.css'
import DataManager from '../../hooks/DataManager';
import { addUserToGroup, getGroupUsers, UserPowerSearch } from '../../Api Methods/Api';
import { Await, Outlet } from 'react-router-dom';
import GroupUserCardContentLayout from './GroupUserCardContentLayout';
import Button1 from '../../components/UI/Button/Button1';

import InputFormCreateGroup from '../../components/forms/InputFormCreateGroup';
import Modal from '../../components/Modal/Modal';
import SearchBar from '../../components/PowerSearch/SearchBar';
import FilterUsersSearch from '../../components/PowerSearch/FilterUsersSearch';
import UserList from '../User Management/UserManagementList';
import check from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png'

const GroupUsers = () => {

    const dataManager = new DataManager();
    const groupID = dataManager.getItem('groupClickedID');

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }
    const dispatch = useDispatch();

    const [groupUsers, setGroupUsers] = useState();
    const [update, setUpdate] = useState(0);
    const [isChecked, setIsChecked] = useState(dataManager.getItem('isChecked'));
    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
    const [modalComponent, setModalComponent] = useState();
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");
    const [name, setName] = useState();
    const [title, setTitle] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const [selectionMade, setSelectionMade] = useState(false);

    const HandleShowModal = () => {
        ModalDialog.current.open();
    };

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        setName("")
        setSuggestions([])
        setSelectionMade(false);
    };

    const ModalDialog = useRef();

    useEffect(() => {
    }, [groupUsers])

    useEffect(() => {
        fetchUsers()
            .then((response) => {
                setGroupUsers(response.data);  // Set state should trigger re-render
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
            });
    }, [update])

    async function fetchUsers() {
        return await getGroupUsers(apiData, groupID, dispatch)
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        dataManager.setItem('isChecked', event.target.checked);
        showAddUserModal();
    };

    const timer = useRef();

    useEffect(() => {
        if (suggestions.length > 0 || selectionMade) {
            showAddUserModal();
        }
        
    }, [suggestions])

    useEffect(() => {
        if (name !== "" && name !== undefined){
            showAddUserModal();
        } 
    }, [name])

    const updateName = (name) => {
        setName(name);
        setSuggestions([]);
        dataManager.setItem('userToInvite', name);
        showAddUserModal();
        setSelectionMade(true);
    }

    const handleFilterLogic = async (data, searchQuery, selectedCategories) => {
        const users = await data;
        const usersEmails = users.map(user => user.email);
        setSuggestions(usersEmails);
      };

    const showAddUserModal = () => {
        setTitle("Add user")
        setModalComponent(
            <div>
                <div>
                    <FilterUsersSearch searchQuery={name} setSearchQuery={setName} placeholder={"Enter users email address"} setIsLoading={false} setFilteredResults={setSuggestions} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]}></FilterUsersSearch>
                    {/* <InputFormCreateGroup label={"Enter users email address"} name={name} setName={setName}></InputFormCreateGroup> */}
                </div>
                {suggestions.length > 0 && (
                        <ul
                            style={{
                                listStyle: 'none',
                                margin: 0,
                                padding: '8px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                position: 'absolute',
                                width: '100%',
                                backgroundColor: '#fff',
                                zIndex: 1000,
                            }}>

                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => updateName(suggestion)}
                                    style={{
                                        padding: '8px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ddd',
                                    }}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                <br></br>
                <div style={{ fontWeight: 300, fontStyle: 'italic' }}><p>Please note, users must have an existing mobile app account before being invited to a group.</p></div>
                <br></br>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={dataManager.getItem('isChecked')}
                            onChange={handleCheckboxChange}
                        />
                        Administrator
                    </label>

                    
                </div>
                <br></br>
                <Button1 onClick={addUser}>Add</Button1>
            </div>
        )
        HandleShowModal();
    }

    const showError = (response) => {
        setTitle("Error")
        setModalComponent(
            <div>
                <div>
                    <h3 style={{ textAlign: "center" }}>Error</h3>
                </div>
                <div>
                    <p style={{ textAlign: "center" }}>{response.msg}</p>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px" // Adds space between buttons
                }}>
                    <Button1 onClick={HandleCloseModal}>Close</Button1>
                </div>
            </div>
        )

        HandleShowModal();
    }
    
    const successModal = () => {
        setTitle('Success')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.ModalIcon} src={check}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>Action completed successfully</h3>
            </div>   
            <br></br>
            <br></br>
            <div>
              <br></br>
              <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
            </div>
          </div>
        )
        HandleShowModal();
      }

    const addUser = async () => {
        const email = name
        if (email != '') {
            let body = {
                groupID: groupID,
                email: email,
                role: "TechSupport",
                owner: dataManager.getItem('isChecked') ? 1 : 0,
            }

            const response = await addUserToGroup(apiData, body, dispatch)
            if (response.success) {
                dataManager.setItem('isChecked', false);
                dataManager.setItem('userToInvite', "");
                HandleCloseModal();
                successModal();
                setUpdate(update + 1);
            } else {
                //error
                showError(response);
            }
        }
    }

    return (
        <>
            <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
                {modalComponent}
            </Modal>

            <div className={classes["grid-container"]}>
                <Await resolve={groupUsers}>
                    <>
                        <div style={{ width: "100%", marginBottom: "20px", marginTop: "0px", gap: "10px" }}>
                            <Button1 style={{ height: "50px", width: "110px", fontSize: "16px" }} onClick={showAddUserModal}>Add User</Button1>
                        </div>

                        <div style={{ display: "flex", width: "100%" }}>
                            {Array.isArray(groupUsers) && groupUsers.length > 0 ? (
                                <UserList data={groupUsers} isGroup={true} update={update} setUpdate={setUpdate}/>
                                // groupUsers.map((user, index) => (
                                //     <li className={classes["grid-li"]} key={index}>
                                //         {/* Key passed to act as index for each card, to make it so when a card is changed it replays the fade from top animation :: key={index + pageIndex * pageSize} */}
                                //         <Card className={classes.card} index={index} color={user}>
                                //             <GroupUserCardContentLayout cardData={user} apiData={apiData} update={update} setUpdate={setUpdate} />
                                //         </Card>
                                //     </li>
                                // ))
                            ) : (
                                <p>No users found</p>
                            )}
                        </div>
                    </>
                </Await>
            </div>
        </>
    )
}

export default GroupUsers;