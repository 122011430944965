import React, { useEffect, useState, useMemo, useCallback } from 'react'
import classes from './Stats2.module.css'
import usersIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/user.png'
import allSitesIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/home.png'
import OnlineIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/online.png'
import OfflineIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/offline.png'
import SpinnerIcon from '../../../assets/portalAssets/spinner.png'
import padlockIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/locks.png'
import sensorIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/sensor.png'
import keyBoxesIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/keybox.png'
import groupsIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/groups.png'
import usersIcon2 from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/user.png'
import controllerIcon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/controller.png'
import empty from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/empty.png'
import TimerIndicator from './Stats2/TimerIndicator'
import { Oval } from 'react-loading-icons'
import { useNavigate } from 'react-router-dom'


const Stats2 = ({ title, value, status, isLoadingStats, isStatic }, props) => {

    const [isMoblie, setIsMoblie] = useState(false)
    const [cssClass, setCssClass] = useState({ stroke: "var(--Primary-Color2)", height: 47.5, speed: 1.5 });
    const navigate = useNavigate();
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 430px)'); // Define your media query here

        // Function to handle changes in the media query
        const handleIsMobile = () => {
            if (window.innerWidth <= 430) {
                setIsMoblie(true)

            } else {
                setIsMoblie(false)
            }
        };

        // Initial check for if screen has been resized
        handleIsMobile(mediaQuery);

        // Add event listener for changes in the media query
        mediaQuery.addListener(handleIsMobile);

        // Clean up by removing event listener when component unmounts
        return () => {
            mediaQuery.removeListener(handleIsMobile);
        };
    }, []);

    useEffect(() => {
        if (!isMoblie) {
            setCssClass({ stroke: "var(--Primary-Color)", height: 47.5, speed: 1.5 });
        } else if (isMoblie) {
            setCssClass({ stroke: "var(--Primary-Color)", height: 30, speed: 1.5 });
        }
    }, [isMoblie]);

    const handleOfflineSitesClick = () => {
        navigate("/AllSites/Offline");
    };
    const handleOnlineSitesClick = () => {
        navigate("/AllSites");
    };
    const handleUsersClick = () => {
        navigate("/AllSites/Users");
    };
    const handlePadlocksClick = () => {
        navigate("/AllSites/Padlocks");
    };
    const handleKeyboxesClick = () => {
        navigate("/AllSites/Keyboxes");
    };
    const handleSensorsClick = () => {
        navigate("/AllSites/Sensors");
    };
    const handleAccountsClick = () => {
        navigate("/AllSites/Groups");
    };
    const handleEmptySitesClick = () => {
    }
    const handleControllersClick = () => {
        navigate("/AllSites/Controllers");
    };


    let specialClasses = null;
    let icon;

    if (title === 'Total users' || title === "Users" || title === "Total portal users") {
        specialClasses = classes.users;
        icon = usersIcon;
    }
    if (title === 'Online sites' || title === 'Online controllers') {
        specialClasses = classes.devicesOnline;
        icon = OnlineIcon;
    }
    if (title === 'Offline sites' || title === 'Offline controllers' || title === 'Total offline controllers' ) {
        specialClasses = classes.devicesOffline;
        icon = OfflineIcon;
    }
    if (title === "Total padlocks" || title === "Padlocks"){
        specialClasses = classes.totalCount;
        icon = padlockIcon;
    }
    if (title === "Total sensors"|| title === "Sensors"){
        specialClasses = classes.totalCount;
        icon = sensorIcon;
    }
    if (title === "Total keyboxes" || title === "Keyboxes"){
        specialClasses = classes.totalCount;
        icon = keyBoxesIcon;
    }
    if (title === "Total groups" || title === "Groups"){
        specialClasses = classes.totalCount;
        icon = groupsIcon;
    }
    if (title === "Total active users" || title === 'Controllers with no users'){
        specialClasses = classes.totalCount;
        icon = usersIcon;
    }
    if (title === 'Total sites'|| title === "All sites") {
        specialClasses = classes.totalCount;
        icon = allSitesIcon;
    }
    if (title === 'All controller count' || title === "Controllers"){
        specialClasses = classes.totalCount;
        icon = controllerIcon;
    }
    if (title === 'Total groups') {
        specialClasses = classes.totalCount;
        icon = groupsIcon;
    }
    if (title === "Offline for more than 1 month"){
        specialClasses = classes.devicesOffline;
        icon = OfflineIcon
    }
    if (title === "Offline for less than 1 month"){
        specialClasses = classes.devicesOffline;
        icon = OfflineIcon
    }
    if (title === "Active offline controllers"){
        specialClasses = classes.devicesOffline;
        icon = OfflineIcon
    }
    if (title === "Empty sites"){
        specialClasses = classes.emptyCount;
        icon = empty
    }

    const cssClasses = `${classes['Top-left']} ${specialClasses}`;

    return (
        <div 
        className={`${classes.container} ${props.container}`}
        {...(title === 'Offline sites' || title === 'Offline controllers' ? { onClick: handleOfflineSitesClick} : {})}
        {...(title === 'Online sites' ? { onClick: handleOnlineSitesClick } : {})}
        {...(title === 'All sites' ? { onClick: handleOnlineSitesClick } : {})}
        {...(title === 'Users' ? { onClick: handleUsersClick } : {})}
        {...(title === 'Padlocks' ? { onClick: handlePadlocksClick } : {})}
        {...(title === 'Sensors' ? { onClick: handleSensorsClick } : {})}
        {...(title === 'Keyboxes' ? { onClick: handleKeyboxesClick } : {})}
        {...(title === 'Groups' ? { onClick: handleAccountsClick } : {})}
        {...(title === 'Empty sites' ? { onClick: handleEmptySitesClick } : {})}
        {...(title === 'Controllers' ? { onClick: handleControllersClick } : {})}

        >
        <div className={classes["Top"]}>
            <h3>{title}</h3>
            {!isStatic && <TimerIndicator className={classes["TimeStatus-component"]} status={status} />}
        </div>

        <div className={classes["Bottom"]}>
            <div className={cssClasses}>
                {isLoadingStats ? (
                    <Oval stroke={cssClass.stroke} height={cssClass.height} speed={cssClass.speed} />
                ) : (
                    <h1>{value.toLocaleString()}</h1>
                )}
            </div>
            <img className={classes.icon} alt={`${title} icon`} src={icon} />
            
            
        </div>
        {title === "Active offline controllers" 
        
        && 
            <div className={classes.activeSites}>
               <p>Excludes controllers with no users</p>
            </div>}
    </div>
    );
}


export default Stats2;