import React, { useEffect, useReducer, useRef, useState } from 'react'
import Modal from '../../../../../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import classes from "./CamectModalForm.module.css"
import Input from '../../../../../CustomDropDown/Input/Input';
import Button1 from '../../../../Button/Button1';
import { addCamectAPI, updateCamectAPI } from '../../../../../../Api Methods/Api';
import { checkAuthLoader } from '../../../../../../auth';

const CamectIPReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const CamectPasswordReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const CamectModalForm = ({ setShowVirtualSource, title, subTitle, placeholders, camect, fetchVirtualSources }) => {

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const apiData = {
        token,
        client
    }
    const [modalComponent, setModalComponent] = useState(null);
    const [modalPageIndex, setModalPageIndex] = useState(0);
    const ModalDialog = useRef();
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        HandelResetModal();
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                HandelResetModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [formIsValid, setFormIsValid] = useState(false);

    const [camectIPState, dispatchCamectIP] = useReducer(CamectIPReducer, {
        value: title === "Edit" ? camect.address : '',
        isValid: null,
    });
    
    const [camectPasswordState, dispatchCamectPassword] = useReducer(CamectPasswordReducer, {
        value: title === "Edit" ? camect.password : '',
        isValid: null,
    });

    const camectIPInputRef = useRef();
    const camectPasswordRef = useRef();
    const { isValid: camectIPIsValid } = camectIPState;
    const { isValid: camectPasswordIsValid } = camectPasswordState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(camectIPIsValid && camectPasswordIsValid)
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [camectIPIsValid, camectPasswordIsValid]);

    const camectIPChangeHandler = (event) => {
        dispatchCamectIP({ type: 'USER_INPUT', val: event.target.value });
    }

    const camectPasswordChangeHandler = (event) => {
        dispatchCamectPassword({ type: 'USER_INPUT', val: event.target.value });
    }

    const validateCamectIPHandler = () => {
        dispatchCamectIP({ type: 'INPUT_BLUR' });
    }

    const validateCamectPasswordHandler = () => {
        dispatchCamectPassword({ type: 'INPUT_BLUR' });
    }

    const HandelResetModal = () => {
        setModalPageIndex(null);
        setShowVirtualSource(null);
    }

    const onClearHandler = () => {
        dispatchCamectIP({ type: 'INPUT_CLEAR', val: "" })
        dispatchCamectPassword({ type: 'INPUT_CLEAR', val: "" })
    }

    const dispatch = useDispatch();

    const submitHandler = async (event) => {
        event.preventDefault();
            const CamectIP = camectIPState.value;
            const CamectPassword = camectPasswordState.value;

            if (title == "Add"){
                const camectDetails = {
                    "uuid": DeviceUUID,
                    "address": CamectIP,
                    "username": "admin",
                    "password": CamectPassword
                }
                const responseData = await addCamectAPI(apiData, camectDetails, dispatch)
                if (true) {
                    HandleCloseModal();
                    onClearHandler();
                    fetchVirtualSources();
                }
            } else if (title == "Edit"){
                const camectDetails = {
                    "id": camect.id,
                    "uuid": DeviceUUID,
                    "address": CamectIP,
                    "username": "admin",
                    "password": CamectPassword
                }
                const responseData = await updateCamectAPI(apiData, camectDetails, dispatch)
                if (true) {
                    HandleCloseModal();
                    onClearHandler();
                    fetchVirtualSources();
                }
            }
    };

    const randomId = Math.random().toString(36).substr(2, 9);

    useEffect(() => {
        switch (modalPageIndex) {
            case 0:
                setModalComponent(
                        <form name={"CAMECT_FORM"}onSubmit={submitHandler} className={classes["container"]} method="dialog">
                            <form autoComplete="off" style={{ display: 'none' }}>
                                <input type="text" name="dummy-username" />
                                <input type="password" name="dummy-password" />
                            </form>
                        <ul className={classes["inputs-list"]}>
                            <li className={classes["input-container"]}>
                                <label className={classes.subTitle}>{subTitle}</label>
                                <Input
                                    className={classes.input}
                                    placeholder={title == "Edit" ? "" : "IP Address"}
                                    ref={camectIPInputRef}
                                    id={`input-ip-${randomId}`}
                                    name={`input-ip-${randomId}`}
                                    type={"text"}
                                    isValid={camectIPState.isValid}
                                    value={camectIPState.value}
                                    onChange={camectIPChangeHandler}
                                    
                                    autoComplete="off"
                                />

                                <Input
                                    className={classes.input}
                                    placeholder={title == "Edit" ? "" : "Password"}
                                    ref={camectPasswordRef}
                                    id={`input-password-${randomId}`}
                                    name={`input-password-${randomId}`}
                                    type={"text"}
                                    isValid={camectPasswordState.isValid}
                                    value={camectPasswordState.value}
                                    onChange={camectPasswordChangeHandler}
                                    
                                    autoComplete="off"
                                />
                            </li>
                        </ul>

                        <Button1 propsclassName={classes.submitButton} type="submit">{title == "Edit" ? "Update" : "Save"}</Button1>
                    </form>
                
                      
                )
                
                    
                HandleShowModal();
                break;
        }
    }, [modalPageIndex, camectIPState, camectPasswordState]); // Empty dependency array ensures it runs only once after initial render

    return (
        <>
            <Modal ref={ModalDialog} title={title + " Camect"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
        </>
    )
}

export default CamectModalForm;