
import React, { Fragment, useEffect, useState, Suspense } from "react";

import classes from '../../../Pages/SearchView/SearchView.module.css';
import { ApiEndpoints } from "../../../store/type/API-Endpoints";
import { NotificationMessages } from "../../../store/type/NotificationMessages";
import { useLoaderData, json, Await } from "react-router-dom";
import Stats2 from "./Stats2";
import { Oval } from "react-loading-icons";
import { getStats } from "../../../Api Methods/Api";
import { useDispatch } from "react-redux";

const StatsList = ({ client, token, companyName }) => {

    // ---------------------------- loader data ---------------------------------
    const { Stats } = useLoaderData();

    // useEffect(() => {
    //     if (stats.isError) {
    //         return stats.message;

    //     }
    // }, [stats])
    //---------------------------------------------------------------------------------------------------------------------

    // ------------------------  Site stats -- fetch recent stat info every 30 seconds // currently no api -------------------    
    const [siteStats, setSiteStats] = useState(Stats)

    const [statsTimer, setStatsTimer] = useState(1);
    const [isLoadingStats, setIsLoadingStats] = useState(false)
    let number = 1;

    const apiData = {
        token,
        client
    }

    const dispatch = useDispatch();
    useEffect(() => {
        // loader is a javascript function only, so gets data and header using sesstion storage.
        const uri = ApiEndpoints.getStatsAPI + companyName;
        const updateStats = async () => {
            setIsLoadingStats(true)
            const responseData = await getStats(apiData, companyName, dispatch);
            setSiteStats(responseData);
            if (responseData) {
                setIsLoadingStats(false);
            }
        };

        const updateStatsTimer = () => {
            setStatsTimer(number);
        }
        // Set up a timer to fetch data every 30 seconds
        const timerId = setInterval(() => {
            number++;
            if (number > 3) {
                number = 1;
                updateStats();
            }
            updateStatsTimer();

        }, 60000);

        // Cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(timerId);
    }, [siteStats]); // Empty dependency array means this effect runs once on mount
    //---------------------------------------------------------------------------------------------------------------------
    return (
        <Fragment>
            <Suspense fallback={
                <div className={classes['stats-container']}>
                    <Stats2 title={"Total sites"} value={"loading"} status={"loading"} isLoadingStats={true} />
                    <Stats2 title={"Online sites"} value={"loading"} status={"loading"} isLoadingStats={true} />
                    <Stats2 title={"Offline sites"} value={"loading"} status={"loading"} isLoadingStats={true} />
                    {/* <Stats2 title={"Empty sites"} value={"loading"} status={"loading"} isLoadingStats={true} /> */}
                </div>
            }>
                <Await resolve={siteStats}>
                    {(loadedSitesStats) =>
                        < div className={classes['stats-container']}>
                            <Stats2 title={"Total sites"} value={loadedSitesStats && (loadedSitesStats.devicesOnline + loadedSitesStats.devicesOffline )|| "..."} status={statsTimer} isLoadingStats={isLoadingStats} />
                            <Stats2 title={"Online sites"} value={loadedSitesStats && loadedSitesStats.devicesOnline} status={statsTimer} isLoadingStats={isLoadingStats} />
                            <Stats2 title={"Offline sites"} value={loadedSitesStats && loadedSitesStats.devicesOffline} status={statsTimer} isLoadingStats={isLoadingStats} />
                            {/* <Stats2 title={"Empty sites"} value={loadedSitesStats && loadedSitesStats.devicesOffline} status={statsTimer} isLoadingStats={isLoadingStats} /> */}
                        </div>
                    }
                </Await>
            </Suspense>

        </Fragment >
    )
}

export default React.memo(StatsList);