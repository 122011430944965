import React, { Children, useEffect, useState, useRef } from 'react'
import classes from "./SiteCardContentLayout.module.css";
import StatusIndicatorCard from '../../../components/UI/Cards/StatusIndicatorCard/StatusIndicatorCard';
import FavouritedIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Favourite - On.svg";
import UnFavouritedIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Favourite - Off.svg";
import ActiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg";
import InactiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg";
import arrowIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import diableNotificationIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import SettingsCog from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/gear.png"
import SuspendDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"
import UnSuspendDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Unlocked.svg"
import ClearLogsIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import ChangeOwnerIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Change User.svg"
import ChangeCamectIntergrationTypeIcon_Off from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - Off.svg";
import ChangeCamectIntergrationTypeIcon_On from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - On.svg";
import CopyDeviceSettingsIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Logs.svg";
import RebootDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__OffButton.svg";
import ResetDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Refresh.svg"
import UserIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/user.png"
import ChangeTagIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Edit-93.svg"
import CompanyIndicatorCard from '../Cards/StatusIndicatorCard/CompanyIndicatorCard';
import OutputPinCircle from './OutputPinCircle';
import InputPinCircle from './InputPinCircle';
import CustomLink from '../../CustomLink/CustomLink';
import Pill_Widget from '../Widgets/Pill_Widget';
import DropdownList from '../Cards/DropdownList';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation } from 'react-router-dom';
import { serviceActions } from '../../../store/service-Slice';
import { changeCamectIntegration, clearDeviceLogs, copyIPIOSettings, copyUsersToAnotherDevice, getDevices, rebootIPIODevice, resetIPIODevice, suspendDevice } from '../../../Api Methods/Api';
import DropDownForm from '../../forms/DropDownForm';
import useClickOutside from '../../hooks/useClickOutside';
import Modal from '../../Modal/Modal';
import InputForm from '../../forms/InputForm';
import { uiActions } from '../../../store/ui-slice';
import { Oval } from 'react-loading-icons';
import SetTag from '../../Modal/ModalLayouts/SetTag';
import Button1 from '../Button/Button1';
import DataManager from '../../../hooks/DataManager';
import check from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png';
import loading from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/loading.png';
import fail from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/cross.png';
import CopyUsersPowersearch from '../../hooks/PowerSearchCopySettings';
import SetNameForUsers from '../../Modal/ModalLayouts/SetName';
import useSetCookie from '../../hooks/UseSetCookie';

const SiteCardContentLayout = ({ cardData, HandleSaveSearchQuery, groupMode, checkedItem, handleCheckboxChange }) => {
    const navigation = useNavigation();
    let { id, mac, key, ip, host, uuid, online, created, lastLoggedIn, tag, networkType, deviceDetails, firmware, type, numInput, numOutput, subGroup, deviceExtraDetails, latestFirmware, OutputPins, InputPins, singleDeviceSettingData, userCount, active, deviceName } = cardData;
    const [status, setStatus] = useState(cardData.status)
    const [name, setName] = useState(cardData.name)
    const dataManager = new DataManager();

    if (deviceName !== "" && deviceName !== null && deviceName !== undefined) {
        tag = deviceName
    }

    const [updateTag, setUpdateTag] = useState(tag)

    useEffect(() => {
        setUpdateTag(tag)
        setName(cardData.name)
        setStatus(cardData.status);
    }, [cardData])

    const DeviceDetails = JSON.parse(deviceDetails)
    const [camectIntegationType, setCamectIntegationType] = useState(DeviceDetails.camectIntegrationType)
    const client = useSelector(state => state.auth.client);
    const companyName = useSelector(state => state.auth.companyName);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);
    const userRole = useSelector(state => state.auth.role);
    //const groups = useSelector(state => state.auth.groups);
    const groups = sessionStorage.getItem('groups');
    const parsedGroups = JSON.parse(groups);
    const groupBreadCrumb = parsedGroups.find((group) => group.name === name);
    const groupPath = groupBreadCrumb.group_path;
    const GroupNamesArraySplit = groupPath.split(">");
    const GroupNamesArray = GroupNamesArraySplit.slice(-3);

    const [suggestions, setSuggestions] = useState([]);
    const [selectionMade, setSelectionMade] = useState(false);
    const [siteID, setSiteID] = useState();
    const [copyUsersChecked, setCopyUsersChecked] = useState(dataManager.getItem('copyUsersChecked'));
    const [autoChooseASiteChecked, setAutoChooseASiteChecked] = useState(dataManager.getItem('autoChooseASite'));
    const [deviceKey, setDeviceKey] = useState("");
    // const [deviceUUID, setDeviceUUID] = useState("");
    // const [deviceID, setDeviceID] = useState("");
    const [queryDeviceList, setQueryDeviceList] = useState("");
    const [myUserID, setMyUserID] = useState("");
    const [nameSet, setNameSet] = useState('');
    const [deviceType, setDeviceType] = useState("");
    const [redraw, setRedraw] = useState(0)
    const [update, setUpdate] = useState(0)
    let newSiteUUID = "";

    const apiData = {
        token,
        client
    }

    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");

    useClickOutside(NotificationCounterRef, buttonRef, dropdownRef, () => {
        setOpenDropdownRowId(null);
    });

    const handleSettingsClick = (rowId) => {

        // If the clicked dropdown is already open, close it
        if (openDropdownRowId === rowId) {
            setOpenDropdownRowId(null);
        } else {
            // Otherwise, open the clicked dropdown
            setOpenDropdownRowId(rowId);
        }
    };

    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        setSuggestions([])
        setQueryDeviceList("")
        setSelectionMade(false)
        setSiteID("")
        setCopyUsersChecked(false)
        dataManager.setItem('copyUsersChecked', false);
        dataManager.setItem('setNameCopyUsers', "")
    }

    useEffect(() => {
        if ((suggestions.length > 0) || ((redraw > 0) && queryDeviceList !== "")){
          showCopySiteSettingsModal(deviceKey);
        } 
      }, [suggestions, redraw])

      useEffect(() => {
        if (queryDeviceList !== "" && queryDeviceList !== " " && queryDeviceList !== null){
          showCopySiteSettingsModal(deviceKey);
        } else {
          setSuggestions([])
        }
          //This is to rerender the modal and show the new data in the query
      }, [queryDeviceList])

      const updateSiteID = (siteID) => {
        setQueryDeviceList(siteID);
        setSuggestions([]);
        dataManager.setItem('siteIDToCopyTo', siteID);
        setRedraw(redraw+1)
        setSelectionMade(true);
    }

    const selectAnEmptySite = (event) => {
        setAutoChooseASiteChecked(event.target.checked);
        dataManager.setItem('autoChooseASite', event.target.checked);
        showCopySiteSettingsModal(deviceKey);
    }

    const showCopySiteSettingsModal = (DeviceKey, type) => {
        setModalTitle("Copy configuration of: " + DeviceKey)
        setModalComponent(
            <div>
              { (<div>
                <label>
                  <input
                    type="checkbox"
                    checked={dataManager.getItem('autoChooseASite')}
                    onChange={selectAnEmptySite}
                  />
                  Automatic site selection
                </label>
                <br></br>
                <p style={{color:"#4C4C4C"}}>The system will select a site ID with no users that is not named.</p>
              </div>)}
        
              <br></br>
              
              {!dataManager.getItem('autoChooseASite') && (
                
                <div>
                <div>
                  <CopyUsersPowersearch apiData={apiData} update={update} setUpdate={setUpdate} suggestions={suggestions} setSuggestions={setSuggestions} queryDeviceList={queryDeviceList} setQueryDeviceList={setQueryDeviceList} redraw={redraw} setRedraw={setRedraw} />
                </div>
            
                {suggestions.length > 0 && (
                  <ul
                    style={{
                      listStyle: 'none',
                      margin: 0,
                      padding: '0px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      position: 'absolute',
                      width: '94%',
                      backgroundColor: '#fff',
                      zIndex: 1000,
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => updateSiteID(suggestion)}
                        style={{
                          padding: '8px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
        
                <div 
                  style={{
                    flexDirection: "column", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    textAlign: "center"
                  }}
                  >
                  <p style={{color:"#4C4C4C"}}>{`Please note, only sites without a name or users will appear in search suggestions.`}</p>
                  <p style={{color:"#4C4C4C"}}>{`If a site isn't listed, you can still copy to it by entering its Site ID.`}</p>
                </div>
            </div>
          )}
        
          <br></br>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={dataManager.getItem('copyUsersChecked')}
                    onChange={handleCopyUsersSelected}
                  />
                  Copy users too?
                </label>
                <br></br>
              </div>
              {dataManager.getItem('copyUsersChecked') && (
                <div>
                  <SetNameForUsers 
                    placeholder={"Name of site for new users in app and in portal:"} 
                    HandleCloseModal={HandleCloseModal} 
                    setNameSet={setNameSet}
                  />
                  <small>(Note, you can change this after)</small>
                </div>
              )}
              <br/>
              <Button1 style={{width:"100%"}} onClick={copySiteSettings}>Copy</Button1>
            </div>
            
          
        );
    
        HandleShowModal();
    }

    useEffect(() => {
    }, [nameSet])

    const [modalTitle, setModalTitle] = useState("title")
    const [modalComponent, setModalComponent] = useState()

    let items = [
        {
            image:
                status !== "suspended"
                    ? SuspendDeviceIcon
                    : UnSuspendDeviceIcon,
            title:
                status !== "suspended"
                    ? "Suspend Site"
                    : "Unsuspend Site",
        },
        {
            image: ClearLogsIcon,
            title: "Clear Logs",
        },
        {
            image: ChangeTagIcon,
            title: "Change portal name",
        },
    ];

    const Firmware = firmware.split('.')
    const firmwareMajor = Firmware[0]
    const firmwareMinor = Firmware[1]
    const DeviceType = type;
    const isOnline = online == "YES" ? true : false;

    if (companyName == "EyeSpyFX") {
        items.splice(3, 0, {
            image: ChangeOwnerIcon,
            title: "Change group",
        });
    }

    // Conditionally push the "Change Owner" object based on firmware version and device type
    if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
        items.splice(4, 0, {
            image: camectIntegationType == 2 ? ChangeCamectIntergrationTypeIcon_On : ChangeCamectIntergrationTypeIcon_Off,
            title: "Change Camect Integration Type",
        },);
    }

    if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7))) {
        items.splice(5, 0, {
            image: CopyDeviceSettingsIcon,
            title: "Copy site configuration",
        });
    }

    if (isOnline) {
        items.splice(6, 0, {
            image: RebootDeviceIcon,
            title: "Reboot Site",
        },
            {
                image: ResetDeviceIcon,
                title: "Reset Site",
            });
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const HandleUipiodOnClick = (DeviceUUID) => {

        dispatch(serviceActions.setDeviceUUID(DeviceUUID));
        navigate("/portal/SiteView/" + DeviceUUID);
    }

    const goToSite = (DeviceUUID) => {
        dispatch(serviceActions.setDeviceUUID(DeviceUUID));
        //useSetCookie("DeviceUUID", DeviceUUID, 1);
        navigate("/AllSites/SiteView/" + DeviceUUID);
    }

    const UserActionHandler = (userAction, suspend, DeviceId, DeviceUUID, userID, DeviceName, DeviceKey, camectIntegationType) => {
        switch (userAction) {
            case 'Unsuspend Site':
            case 'Suspend Site':

                const SuspendDevice = async () => {
                    const response = await suspendDevice(apiData, suspend, DeviceId, dispatch);

                    if (response) {
                        if (status == "suspended") {
                            setStatus("available")
                        } else {
                            setStatus("suspended")
                        }
                        setOpenDropdownRowId(null);
                    }
                }
                SuspendDevice();
                break;
            case 'Clear Logs':
                const ClearLogs = () => {
                    setOpenDropdownRowId(null);

                    const HandleClearLogs = async () => {
                        const response = await clearDeviceLogs(apiData, DeviceUUID, userID, dispatch);
                        if (response) {
                            HandleCloseModal();
                        }
                    }

                    const Cancel = () => {
                        HandleCloseModal();
                    }

                    setModalTitle("Clear Logs");
                    setModalComponent(
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
                            <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
                                <h4 style={{ margin: "0px" }}>{"Are you sure you want to clear site: " + key + " log's. This will be"}</h4>
                                <h4 style={{ margin: "0px", color: "red" }}>Permanent</h4>
                                <h4 style={{ margin: "0px" }}>!</h4>
                            </div>
                            <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
                                <Button1 propsclassName={classes.CancelButton} onClick={Cancel}>Cancel</Button1>
                                <Button1 propsclassName={classes.ClearLogs} onClick={() => HandleClearLogs()}>Clear Logs</Button1>
                            </div>
                        </div>
                    )
                    HandleShowModal()
                }
                ClearLogs();
                break;
            case 'Change portal name':
                const ChangeTag = async () => {
                    setOpenDropdownRowId(null);
                    setModalTitle("Change portal name")
                    setModalComponent(<SetTag placeholder={tag} DeviceUUID={DeviceUUID} HandleCloseModal={HandleCloseModal} dispatch={dispatch} setUpdateTag={setUpdateTag} setSiteDevices={""} />)
                    HandleShowModal()
                }
                ChangeTag();
                break;
            case 'Change group':
                const ChangeOwner = async (DeviceKey) => {
                    setModalTitle("Change group for: " + DeviceKey)
                    setModalComponent(<DropDownForm apiData={apiData} DeviceUUID={DeviceUUID} companyName={name} NotificationTitle={tag || key} label={"Please select a new device group below"} dropDownOptions={['EyeSpyFX', 'RE:SURE', 'PARFAS', 'PORTAL_SECURITY', 'DCC', 'SOLO', 'CSC', 'CPS', 'EES', 'WEEBIG', 'KNOWIO', '12TH_LOCK']} HandleCloseModal={HandleCloseModal} setName={setName} />)
                    setOpenDropdownRowId(null);
                    HandleShowModal()
                }
                ChangeOwner(DeviceKey);

                break;
            case 'Change Camect Integration Type':
                const Changecamectintergrationtype = async () => {
                    const CamectIntegationType = camectIntegationType == 2 ? 1 : 2;
                    const notificationMessage = CamectIntegationType == 1 ? "Whole Camect" : "Per Cam Arming"
                    const response = await changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, DeviceName, dispatch)
                    if (response) {
                        setCamectIntegationType(CamectIntegationType)
                        dispatch(
                            uiActions.renderNotification({
                                status: 'successful',
                                title: tag || key,
                                message: "Camect integration changed to " + notificationMessage,
                            })
                        );
                        setOpenDropdownRowId(null);
                    }
                }
                Changecamectintergrationtype();
                break;
            case 'Copy site configuration':
                const Copydevicesettings = async (apiData, fromDeviceUUID) => {
                    setDeviceKey(DeviceKey);
                    dataManager.setItem('deviceUUIDToCopyFrom', DeviceUUID);
                    dataManager.setItem('deviceIDToCopyFrom', DeviceId);
                    setMyUserID(userID);
                    setDeviceType(type);
                    showCopySiteSettingsModal(DeviceKey, type);
                  }
                  Copydevicesettings(apiData, DeviceUUID);
                  break;
            case 'Reboot Site':
                const RebootDevice = async () => {
                    rebootIPIODevice(apiData, DeviceUUID, userID, DeviceName);
                    setOpenDropdownRowId(null);
                }
                RebootDevice();
                break;
            case 'Reset Site':
                const ResetDevice = async () => {
                    setModalTitle("Are you sure?")
                    setModalComponent(
                        <div>
                            <div style={{ textAlign: "center" }}>
                            <h2>Are you sure you want to reset: {DeviceKey}</h2>
                            </div>
                            <p style={{ textAlign: "center" }}>This will revert all settings & customisations to factory default.</p>
                            <p style={{ textAlign: "center" }}>This action cannot be undone!</p>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px" // Adds space between buttons
                            }}>
                                <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
                                <Button1 onClick={() => resetSite(apiData, DeviceUUID, userID, DeviceName, dispatch)}>Yes, I'm sure</Button1>
                            </div>
                        </div>
                    )
                }
                HandleShowModal();
                ResetDevice();
            }
    }

    const successModal = (message, isCopyConfig) => {
        setModalTitle('Success')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.ModalIcon} src={check}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
            </div>   
            <br></br>
            <br></br>
            <div>
              {isCopyConfig && (<Button1 style={{width:"100%"}} onClick={() => goToSite(newSiteUUID)}>Go to new site</Button1>)}
              <br></br>
              {isCopyConfig && (<Button1 style={{width:"100%"}} onClick={() => goToSite(dataManager.getItem('deviceUUIDToCopyFrom'))}>Go to original site</Button1>)}
              <br></br>
              <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
            </div>
          </div>
        )
        HandleShowModal();
      }

    const resetSite = async (apiData, DeviceUUID, userID, DeviceName, dispatch) => {
        const response = await resetIPIODevice(apiData, DeviceUUID, userID, DeviceName, dispatch)
        if (response.success) {
            successModal("Site reset successfully!", false)
        }
        setOpenDropdownRowId(null);
        //HandleCloseModal();
    }

    const errorModal = (message) => {
        setModalTitle('Error')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.ModalIcon} src={fail}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
            </div>   
            <br></br>
            <br></br>
            <div>
              <Button1 onClick={copySiteSettings}style={{width:"100%"}}>Try again</Button1>
              <br></br>
              <Button1 style={{width:"100%"}} onClick={HandleCloseModal}>Cancel</Button1>
            </div>
          </div>
        )
        HandleShowModal();
      }
    
      const loadingModal = (message) => {
        setModalTitle('Please wait...')
        setModalComponent(
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img className={classes.ModalIcon} src={loading}>
            </img>
            <br></br>
            <br></br>
            <div>
                <h3 style={{ textAlign: "center", color:"#858585"}}>{message}</h3>
            </div>   
            <br></br>
            <br></br>
          </div>
        )
        HandleShowModal();
      }
    
      const copySiteSettings = async () => {
        loadingModal("Copying site configuration, please wait...");

        const copyUsers = dataManager.getItem('copyUsersChecked');    
        const fromUUID = dataManager.getItem('deviceUUIDToCopyFrom');
        let toUIPIOID = queryDeviceList;
        const siteName = dataManager.getItem('setNameCopyUsers');
        const auto = dataManager.getItem('autoChooseASite')
        if (auto){
          toUIPIOID = ""
        }
        loadingModal();
    
          const response = await copyIPIOSettings(apiData, fromUUID, toUIPIOID, auto, dispatch);
          if (response.success){
            newSiteUUID = response.data[0].uuid;
            if (copyUsers){
              if (auto){
                handleCopyUsers(siteName, auto, response.data[0].key);
              } else {
                handleCopyUsers(siteName, auto, "");
              }
              
              loadingModal("Site configuration copied successfully! Now copying users, please wait...")
            } else {
              if (auto){
                successModal(`Site configuration copied successfully! \n${"Site ID: ", response.data[0].key}`, true);
              } else {
                successModal("Site configuration copied successfully!", true);
              }
    
              //setPageFetchedIndex(pageFetchedIndex)
            }
          } else {
            //error
            if (auto){
              errorModal("Failed to automatically copy site configuration. \nUnable to find a suitable site, please enter the Site ID manually")
            } else {
              errorModal("Failed to copy site configuration, please try again.");
            }
          } 
      }
    
      const handleCopyUsers = async (siteName, auto, newUIPIOID) => {
        const deviceID = dataManager.getItem('deviceIDToCopyFrom');
        let response = "";
        if (auto){
          response = await copyUsersToAnotherDevice(apiData, deviceID, newUIPIOID, siteName, userID, dispatch);
        } else {
          response = await copyUsersToAnotherDevice(apiData, deviceID, queryDeviceList, siteName, userID, dispatch);
        }
        if (response.success){
          successModal("Site configuration & users copied successfully!", true)
          //setPageFetchedIndex(pageFetchedIndex)
          dataManager.setItem('setNameCopyUsers', "")
        } else {
          errorModal("Failed to copy users, please try again.");
        }
      }

      const handleCopyUsersSelected = (event) => {
        setCopyUsersChecked(event.target.checked);
        dataManager.setItem('copyUsersChecked', event.target.checked);
        showCopySiteSettingsModal(deviceKey);
      }
    

    const favorited = cardData.favorited || 0;
    let isDeviceOnline;
    if (online === "YES") {
        isDeviceOnline = 1
    } else {
        isDeviceOnline = 0
    }

    const [toggleFavoritedSite, SetToggleFavoritedSite] = useState(favorited);
    const [toggleNotifications, setToggleNotifications] = useState();

    useEffect(() => {
        setToggleNotifications(singleDeviceSettingData[0]);
        SetToggleFavoritedSite(favorited);
    }, [cardData]);

    const outputs =
    {
        output1: 1,
        output2: 0,
        output3: 0,
        output4: 1
    };

    const inputs =
    {
        input1: 1,
        input2: 1,
        input3: 1,
        input4: 1,
        input5: 0,
        input6: 1,
        input7: 1,
        input8: 0,
    };

    const ifSettingsCogIsClicked = openDropdownRowId === id
    const suspend = status == "suspended" ? "available" : "suspended";
    const showHiddenFeatures = userRole === "TechSupport";
    const showSerial = companyName === 'EyeSpyFX' || companyName === "RE:SURE"

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
                {modalComponent}
            </Modal>
            <div className={classes.GroupCardContentLayout}>
                <div className={classes["top-wrapper"]}>
                    <div className={classes["siteDetails-layout"]} style={{ opacity: groupMode ? !checkedItem && "30%" : online == "NO" && "50%" }}>
                        <h3 style={{ margin: "0px" }} className={`${classes.tagName} ${classes.ellipsis}`}>{updateTag || "No site name"}</h3>

                        <div style={{ display: "flex" }}>
                            <h3 style={{ fontWeight: "400", margin: "0px" }} >{updateTag === "none" ? "" : key || "..."}</h3>
                            <h3 style={{ fontWeight: "500", marginLeft: "10px", marginTop: "0px", marginRight: "0px", marginBottom: "0px" }} >{"(v"}</h3>
                            <h3 style={{ fontWeight: "500", margin: "0px" }} >{firmware || "..."}</h3>
                            <h3 style={{ fontWeight: "500", margin: "0px" }} >{")"}</h3>
                        </div>

                        <div style={{ display: "flex", height: "10px", opacity: "50%" }}>
                            <h3 style={{ fontWeight: "500", margin: "0px" }} >{subGroup || " "}</h3>
                        </div>
                    </div>

                    <div className={classes["icons-container"]} style={{ opacity: "100%" }}>
                        {groupMode && <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                            <input
                                type="checkbox"
                                id={`checkbox-${uuid}`}
                                className={classes["circular-checkbox"]}
                                checked={checkedItem}
                                onChange={() => handleCheckboxChange(uuid)}
                            />
                            <label
                                htmlFor={`checkbox-${uuid}`}
                                className={classes['custom-checkbox']}>
                            </label>
                        </div>}

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                            {!groupMode && showHiddenFeatures && 
                            <DropdownList
                                item={id}
                                icon={SettingsCog}
                                onClick={handleSettingsClick}
                                ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                                buttonRef={buttonRef}
                                ref={dropdownRef}
                                title={"Actions"}
                                className={classes.container}
                                onUserActionClick={(userAction) =>
                                    UserActionHandler(
                                        userAction,
                                        suspend,
                                        id,
                                        uuid,
                                        userID,
                                        name,
                                        key,
                                        camectIntegationType
                                    )
                                }
                                listItems={items}
                            />}
                        </div>
                        <br></br>
                        {!groupMode && <div className={classes.userCount}>
                            {userCount}
                            <img className={classes.Icon} src={UserIcon} />
                        </div>}
                    </div>
                </div>

                <CustomLink className={classes.Link} to={`/AllSites/SiteView/${uuid}`} onClick={HandleSaveSearchQuery} style={{ opacity: online == "NO" && "50%" }}>
                    <div className={classes["middle-wrapper"]}>
                        <div className={classes["indicatorsCards-container"]}>
                        {GroupNamesArray[0] === "EyeSpyFX" ? (
                            <CompanyIndicatorCard companyName={GroupNamesArray[0]} />
                            ) : (
                            (() => {
                                let lastGroupName = GroupNamesArray[GroupNamesArray.length - 1];
                                if (lastGroupName && lastGroupName.trim() === "RE:SURE") {
                                lastGroupName = "RESURE";
                                }
                                return <CompanyIndicatorCard companyName={lastGroupName} />;
                            })()
                            )}
                        </div>
                    </div>

                    <div className={classes["bottom-wrapper"]} to={`/AllSites/SiteView/${uuid}`} onClick={HandleSaveSearchQuery}>
                        <div>
                            <ul className={classes.outputs}>
                                <OutputPinCircle outputs={outputs} OutputPins={OutputPins} />
                            </ul>
                        </div>

                        <div className={classes["bottom-wrapper_divider"]} />
                        <div>
                            <ul className={classes.inputs}>
                                <InputPinCircle inputs={inputs} InputPins={InputPins} />
                            </ul>
                        </div>
                        <div className={classes.arrowButton} to={`/AllSites/SiteView/${uuid}`} onClick={HandleSaveSearchQuery}>
                            {navigation.state != "loading" ? <img className={classes.Icon} src={arrowIcon} /> :
                                < Oval stroke="var(--Primary-Color)" height={27} width={27} speed={1.5} />
                            }
                        </div>
                    </div>
                </CustomLink >
            </div >
        </>
    )
}

export default SiteCardContentLayout;
