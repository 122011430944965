import classes from "./SiteCardContentLayout.module.css";
import StatusIndicatorCard from '../Cards/StatusIndicatorCard/StatusIndicatorCard';

import ActiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg";
import InactiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg";

import arrowIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import SettingsCog from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"
import UserIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__User.svg"

import CompanyIndicatorCard from '../Cards/StatusIndicatorCard/CompanyIndicatorCard';
import OutputPinCircle from './OutputPinCircle';
import InputPinCircle from './InputPinCircle';
import CustomLink from '../../CustomLink/CustomLink';
import Pill_Widget from '../Widgets/Pill_Widget';
import DropdownList from '../Cards/DropdownList';

import Modal from '../../Modal/Modal';

import { Oval } from 'react-loading-icons';

const outputs = [
    {
        notificationActive: 1,
        pinState: 2,
        delay: 0,
        lastSwitchTime: 1711262245,
        notificationSound: 'ping.wav',
        length: 5,
        active: 1,
        pinName: 'Output 1',
        pinStatusOff: 'Disarmed',
        type: 'LATCH',
        pinStatusOn: 'Armed',
        Pin: 5,
        icon: 'default',
        DeviceUUID: 'b1889361-dd17-4ea6-a3b0-c78d5e590703'
    },
    {
        notificationActive: 1,
        pinState: 2,
        delay: 0,
        lastSwitchTime: 1711225691,
        notificationSound: 'ping.wav',
        length: 5,
        active: 1,
        pinName: 'Output 2',
        pinStatusOff: 'Disarmed',
        type: 'PULSE',
        pinStatusOn: 'Armed',
        Pin: 6,
        icon: 'default',
        DeviceUUID: 'b1889361-dd17-4ea6-a3b0-c78d5e590703'
    },
    {
        notificationActive: 1,
        pinState: 2,
        delay: 0,
        lastSwitchTime: 1711262101,
        notificationSound: 'ping.wav',
        length: 5,
        active: 1,
        pinName: 'Output 3',
        pinStatusOff: 'Disarmed',
        type: 'LATCH',
        pinStatusOn: 'Armed',
        Pin: 13,
        icon: 'default',
        DeviceUUID: 'b1889361-dd17-4ea6-a3b0-c78d5e590703'
    },
    {
        notificationActive: 1,
        pinState: 2,
        delay: 0,
        lastSwitchTime: 1711262246,
        notificationSound: 'ping.wav',
        length: 5,
        active: 1,
        pinName: 'Output 4',
        pinStatusOff: 'Disarmed',
        type: 'LATCH',
        pinStatusOn: 'Armed',
        Pin: 19,
        icon: 'default',
        DeviceUUID: 'b1889361-dd17-4ea6-a3b0-c78d5e590703'
    }
]

const inputs = [
    {
        notificationActive: 1,
        pinState: 1,
        delay: 0,
        lastSwitchTime: 0,
        notificationSound: 'pingLoud.wav',
        length: 0,
        active: 0,
        pinName: 'Input 1',
        pinStatusOff: 'Disarmed',
        type: 'INPUT',
        pinStatusOn: 'Armed',
        icon: 'default'
    },
    {
        notificationActive: 1,
        pinState: 1,
        delay: 0,
        lastSwitchTime: 0,
        notificationSound: 'pingLoud.wav',
        length: 0,
        active: 0,
        pinName: 'Input 2',
        pinStatusOff: 'Disarmed',
        type: 'INPUT',
        pinStatusOn: 'Armed',
        icon: 'default'
    },
    {
        notificationActive: 1,
        pinState: 1,
        delay: 0,
        lastSwitchTime: 0,
        notificationSound: 'pingLoud.wav',
        length: 0,
        active: 0,
        pinName: 'Input 3',
        pinStatusOff: 'Disarmed',
        type: 'INPUT',
        pinStatusOn: 'Armed',
        icon: 'default'
    },
    {
        notificationActive: 1,
        pinState: 1,
        delay: 0,
        lastSwitchTime: 0,
        notificationSound: 'pingLoud.wav',
        length: 0,
        active: 0,
        pinName: 'Input 4',
        pinStatusOff: 'Disarmed',
        type: 'INPUT',
        pinStatusOn: 'Armed',
        icon: 'default'
    }
]

const SiteCardLoadingCard = () => {

    return (
        // <div className={`${classes.GroupCardContentLayout}`} >

        //     <div className={classes["top-wrapper"]}>
        //         {/* <div className={classes["siteDetails-layout"]}>
        //             <h3 style={{ margin: "0px" }} className={`${classes.tagName} ${classes.ellipsis} ${classes["skeleton-loader"]}`}>{"key"}</h3>
        //             <h3 style={{ fontWeight: "400", margin: "0px" }} className={classes["skeleton-loader"]} >{"tag"}</h3>
        //         </div> */}

        //         <div className={classes["siteDetails-layout"]}>
        //             <div className={classes["skeleton-loader"]} style={{ height: '21.5px', width: "135px" }} />
        //             <div className={classes["skeleton-loader"]} style={{ height: '21.5px', width: "95px" }} />
        //         </div>


        //         <div className={classes["icons-container"]}>

        //             {/* Removed click from notifications, once api has been add onClick back */}
        //             {/* {userCount != undefined ?
        //                 <div className={classes.userCount}>
        //                     {userCount}
        //                     <img className={classes.Icon} src={UserIcon} />
        //                 </div> :
        //                 <img className={classes.Icon} src={toggleNotifications ? ActiveNotificationBellIcon : InactiveNotificationBellIcon} />
        //             } */}
        //             <div className={classes["skeleton-loader"]} style={{ height: '32px', width: "32px"}} />
        //             <div className={classes["skeleton-loader"]} style={{ height: '32px', width: "52px"}} />

                    
        //         </div>

        //     </div>

        //     <div className={classes.Link} >
        //         <div className={classes["middle-wrapper"]}>
        //             <div className={classes["indicatorsCards-container"]}>
        //                 <div className={classes["skeleton-loader"]} style={{ height: '30px', width: "110px" }} />
        //                 <div className={classes["skeleton-loader"]} style={{ height: '30px', width: "110px" }} />
        //                 <div className={classes["skeleton-loader"]} style={{ height: '30px', width: "52px" }} />
        //             </div>
        //         </div>

        //         <div className={classes["bottom-wrapper"]}  >
        //             <div>
        //                 <ul className={`${classes.outputs} `}>
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                 </ul>
        //             </div>

        //             <div className={classes["bottom-wrapper_divider"]} />
        //             <div>
        //                 <ul className={`${classes.inputs} `}>
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                     <div className={classes["skeleton-loader"]} style={{ height: '22px', width: "22px", borderRadius: "50%" }} />
        //                 </ul>
        //             </div>
        //             <div className={classes.arrowButton} >
        //                 {/* {navigation.state != "loading" ? <img className={classes.Icon} src={arrowIcon} /> :
        //                     < Oval stroke="var(--Primary-Color)" height={27} width={27} speed={1.5} />
        //                 } */}
        //             </div>

        //         </div>
        //     </div >
        // </div >

            <div className={classes.ListItemSkeleton}>
        {/* Top section with key details */}
        <div className={classes["list-item-wrapper"]}>
            {/* Skeleton for title or main text */}
            <div className={classes["skeleton-loader"]} style={{ height: "20px", width: "250px", marginBottom: "8px" }} />
            {/* Skeleton for subtitle or secondary text */}
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "200px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "180px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "150px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "180px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "100px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "120px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "100px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "300px" }} />
            <div className={classes["skeleton-loader"]} style={{ height: "16px", width: "150px" }} />
            
            
        </div>

        {/* Right section for icons or actions */}
        <div className={classes["list-item-actions"]}>
            <div className={classes["skeleton-loader"]} style={{ height: "32px", width: "32px", borderRadius: "50%" }} />
        </div>
    </div>
    )
}

export default SiteCardLoadingCard;




