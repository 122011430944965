import classes from "./GridList.module.css";
import Card from "./Card";
import SiteCardLoadingCard from "../UI/DataLayouts/SiteCardLoadingCard";
import GridPaginationControls from "../Table/Table/GridPaginationControls";

const DeviceLoadingGrid = ({ CardCount }) => {
    const cardCount = CardCount || 20;
    const cards = [];

    // Use a for loop to create 20 card elements
    for (let i = 1; i <= cardCount; i++) {
        cards.push(<SiteCardLoadingCard />); // Assign a key here
    }

    return (
        <div className={classes["list-container-loader"]}>
            {/* Styling has been added to style cards when less than 10 */}
            <ul className={classes["list-ul-loader"]}>
                {cards.map((card, index) => {
                    return (
                        <li className={classes["list-li-loader"]} key={index}>
                            {/* Key passed to act as index for each card, to make it so when a card is changed it replays the fade from top animation :: key={index + pageIndex * pageSize}  */}
                            <div className={classes["list-item-container"]}>
                                {card}
                            </div>
                        </li>
                    )
                })}
            </ul>

            <GridPaginationControls pageIndex={"..."} nextPage={() => { }} previousPage={() => { }} setPageIndex={() => { }} pageCount={"..."} setPageSize={() => { }} PageSize={"..."} resultCount={"..."} />
        </div>
    );
}

export default DeviceLoadingGrid;
