import React, { Suspense, useMemo, useState, useEffect } from 'react'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useDispatch, useSelector } from 'react-redux';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { getCompanyUserList, getUsersDevices } from '../../Api Methods/Api';
import DevicesGrid from '../../components/GridList/DevicesGrid';
import FilterUsersDeviceSearch from '../../components/PowerSearch/FilterUsersDeviceSearch';
import ActivityTableLayout from '../../components/Table/ActivityTableLayout';
import PortalUsersTableLayout from '../../components/Table/PortalUsersTableLayout';
import UserDevicesTableLayout from '../../components/Table/UserDevicesTableLayout';
import SearchBar from '../SearchView/Search/SearchBar';
import PortalActivityPage from '../PortalActivity/PortalActivityPage';
import classes from "./UserDevicesPage.module.css"
import DataManager from '../../hooks/DataManager';
import DevicesList from '../../components/GridList/DevicesList';
import DeviceLoadingGrid from '../../components/GridList/DeviceLoadingGrid';
import { da } from 'date-fns/locale';

const UserDevicesPage = () => {

  const dataManager = new DataManager()

  const [UserDevices, setUserDevices] = useState([]);

  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);
  let ClickedUserID = dataManager.getItem('clickedUserID')
  const companyName = useSelector(state => state.auth.companyName);
  const [update, setUpdate] = useState(0)
  const pageFetchedIndex = dataManager.getItem('pageIndex')
  const [loading, setIsLoading] = useState(true)
  const [filteredData, setFilteredData] = useState([]);

  const apiData = {
    token,
    client
  }

  const dispatch = useDispatch();
  useEffect(() => {
    const HandleUsersDevices = async () => {
      if (dataManager.getItem('clickedOnHeader')){
        ClickedUserID = sessionStorage.getItem('userID');
        dataManager.setItem('clickedOnHeader', false)
      }
      const data = await getUsersDevices(apiData, ClickedUserID, companyName, dispatch)
      setFilteredData(data)
      setUserDevices(data)
      setIsLoading(false)
    }

    HandleUsersDevices();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const handleDeviceFilter = () => {
      const filtered = UserDevices.filter((item) =>
        Object.values(item)
          .some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase()))
      );
      dataManager.setItem('userSitesSearchTerm', searchQuery); 
      setFilteredData(filtered); 
    };
  
    if (searchQuery !== dataManager.getItem('userSitesSearchTerm')) {
      handleDeviceFilter(); 
    }
  }, [searchQuery]);

  return (  
    <Await resolve={filteredData}>
      {(loadedUserDevices) => {
        return (
          <>
            <div style={{ width: "100%", minWidth: "1000px", height: "100%", minHeight:"500px" }} >
              {loading ? (
                  <div style={{ display: "flex", width: "100%" }}>
                      <DeviceLoadingGrid
                      CardCount={
                          20
                      }
                      />
                  </div>
                ) : (
                <div style={{width: "100%"}}>
                  <div className={classes.searchContainer}>
                    <SearchBar
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{
                          padding: "10px", // Adjust padding to reduce height
                          width: "300px",    // Set a specific width
                          fontSize: "14px",  // Adjust font size for better proportions
                          boxSizing: "border-box", // Ensure consistent sizing
                          borderRadius: "4px", // Add slight rounding for aesthetics
                      }}
                    />  
                    {searchQuery !== "" && <div>
                      <p>{filteredData.length} results</p>
                    </div>} 
                  </div>

                  <DevicesList 
                    devices={loadedUserDevices}
                    filtering={""}
                    pageFetchedIndex={1}
                    isUsersSites={true}
                    groupMode={false}
                    update={update}
                    setUpdate={setUpdate}
                    hideControls={true}
                    isSortable={true}
                  />
                  </div>
                )
              }
            </div>
        </>
        )
      }
    } 
  </Await>
  )
}

export default UserDevicesPage

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');
  const userID = sessionStorage.getItem('ClickedUserID');

  const apiData = {
    token,
    client
  }

  return defer({
    //userDevices: getUsersDevices(apiData, userID, companyName)
  })
}
