import React, { useState, Suspense, useEffect } from 'react'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useDispatch, useSelector } from 'react-redux';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { getAllUsersPaged, getCompanyUserList, } from '../../Api Methods/Api';
import UsersGrid from '../../components/GridList/UsersGrid';
import FilterSearch from '../../components/PowerSearch/PowerSearch';
import FilterUsersSearch from '../../components/PowerSearch/FilterUsersSearch';

import classes from "./UserManagementPage.module.css"
import UserList from './UserManagementList';
import GridPaginationControls from '../../components/Table/Table/GridPaginationControls';
import DataManager from '../../hooks/DataManager';
import Stats2 from '../../components/UI/Stats/Stats2';

const UserManagementPageCardGrid = () => {

  const dataManager = new DataManager();

  const [filteredResults, setFilteredResults] = useState([]);
  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const apiData = {
    token,
    client
  }
 
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(dataManager.getItem('usersPageNumber'));
  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();

  //Fetch all users on mount
  useEffect(() => {
    const GetAllUsersPaged = async () => {
      const response = await getAllUsersPaged(apiData, pageSize, pageNumber, dispatch);
      setFilteredResults(response[0].users)
      setTotalUsers(response[0].totalUsers)
      setStatsLoading(false)
      setTotalPages(Math.round(totalUsers / pageSize));
    }

    GetAllUsersPaged();
  }, [pageSize, pageNumber]) 

  const HandleNextPage = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1)
    dataManager.setItem('usersPageNumber', prevPageNumber => prevPageNumber + 1)
  }

  const HandlePreviousPage = () => {
      setPageNumber(prevPageNumber => prevPageNumber - 1)
      dataManager.setItem('usersPageNumber', prevPageNumber => prevPageNumber - 1)
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [isloading, setIsLoading] = useState(false)
  const [statsLoading, setStatsLoading] = useState(true)

  const handleFilterLogic = async (data, searchQuery, selectedCategories) => {
    const users = await data;
    setFilteredResults(users);
  };

  useEffect(() => {
  }, [filteredResults])

  return (
    
    <div className={classes['userManagementPage_container']}>
      <Await resolve={totalUsers}>
        {(loadedTotalUsers) => 
          <div style={{ display: "flex", width: "100%" }}>
            <Stats2 title={"Total active users"} value={loadedTotalUsers}  isLoadingStats={statsLoading} isStatic={true} />
          </div>
        }
      </Await>
              
              

      <FilterUsersSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Start typing to search users..."} setIsLoading={setIsLoading} setFilteredResults={setFilteredResults} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]} />
      <Suspense 
        fallback={
          <div className={classes["loadingSpinner-container"]}>
          </div>
        }>
        <Await resolve={filteredResults}>
          
          {(loadedFilteredResults) =>
            <div>
              <div style={{ display: "flex", width: "100%" }}>
                <h3>{isloading && "loading"}</h3>
                <UserList data={loadedFilteredResults} apiData={apiData} />
              </div>
              <br></br>
              <div>
                <GridPaginationControls 
                  pageIndex={pageNumber}
                  nextPage={HandleNextPage}
                  previousPage={HandlePreviousPage}
                  setPageIndex={setPageNumber}
                  pageCount={totalPages}
                  setPageSize={setPageSize}
                  PageSize={pageSize}
                  totalDevices={totalUsers} //Total user count
                  setTotalDevices={setTotalUsers} //set total user count
                />
              </div>
            </div>
          }
        </Await>
      </Suspense>
    </div>
  )
}

export default UserManagementPageCardGrid

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');

  const apiData = {
    token,
    client
  }

  return defer({
    // portalUsers: getCompanyUserList(apiData, companyName),
  })
}
