import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./header.module.css";
import { useSelector } from "react-redux";
import EyeSpyFXLogo from '../../../assets/eyespyfx-logo.png';
import ParfasLogo from "../../../assets/parfas-logo.png"
import ResureLogo from "../../../assets/resure-logo.png"
import KnowioLogo from "../../../assets/knowio-logo.png"
import PortalLogo from "../../../assets/portal-logo.png"
import NotificationDropDownList from "../Cards/NotificationDropDownList";
import NotificationBell from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Header/__Notifications.svg"
import hambugerIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Header/__Menu.svg";
import { uiActions } from "../../../store/ui-slice";
import { Link, useLocation } from "react-router-dom";
import Modal from "../../Modal/Modal";
import SetTag from "../../Modal/ModalLayouts/SetTag";
import { Audio, BallTriangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, SpinningCircles, TailSpin, ThreeDots } from "react-loading-icons";
import { changeCamectIntegration } from "../../../Api Methods/Api";
import { authActions } from "../../../store/auth-Slice";
import { useNavigate } from "react-router-dom";
import { serviceActions } from "../../../store/service-Slice";
import DataManager from "../../../hooks/DataManager";
import { single } from "rxjs";

const pages = ["Dashboard", "AllSites", "Offline", "Users", "Groups", "Padlocks", "Keyboxes", "Sensors", "UserSites", "Activity", "SiteView"];


const Header = ({ isSocketConnected, findClosestToEnd }) => {
    const companyName = useSelector(state => state.auth.companyName);
    let logo;
    let logoClass;
    switch (companyName) {
        case "EyeSpyFX":
            logo = KnowioLogo;
            logoClass = classes.logo;
            break;
        case "PARFAS":
            logo = ParfasLogo;
            logoClass = classes.logo;
            break;
        case "RE:SURE":
            logo = ResureLogo;
            logoClass = classes.logo;
            break;
        case "SOLO":
            logo = ResureLogo;
            logoClass = classes.logo;
            break;
        case "PORTAL_SECURITY":
            logo = PortalLogo;
            logoClass = classes.logo;
            break;
        case "CPS":
            logo = ResureLogo;
            logoClass = classes.logo;
            break;
        case "EES":
            logo = ResureLogo;
            logoClass = classes.logo;
            break;
        case "KNOWIO":
            logo = KnowioLogo;
            logoClass = classes.logo;
            break;
        default:
            logo = KnowioLogo;
            logoClass = classes.logo;
    }

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const [update, setUpdate] = useState(0);

    const apiData = {
        token,
        client
    }

    const dataManager = new DataManager();

    const firstName = useSelector(state => state.auth.firstName);
    const singleDeviceHeaderData = useSelector(state => state.service.singleDeviceHeaderData);
    const [headerData, setHeaderData] = useState(singleDeviceHeaderData);
    console.log(singleDeviceHeaderData)

    const dispatch = useDispatch();

    useEffect(() => {
        setHeaderData(singleDeviceHeaderData);
    }, [singleDeviceHeaderData, update])

    let SiteStatus = (headerData.online == "YES") ? "Online" : "Offline";

    let camectEnabledCompanies = [
        "RE:SURE", 
        "PORTAL_SECURITY", 
        "CSC", 
        "DCC",
        "EES", 
        "MAGNUM_SECURITY",
        "RED CCTV", 
        "EyeSpyFX", 
        "KNOWIO"
    ]
    
    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    }

    //---------------------------------------------------------------------------------------------

    const Changecamectintergrationtype = async () => {
        const CamectIntegationType = singleDeviceHeaderData.camectIntegrationType == 2 ? 1 : 2;
        const notificationMessage = CamectIntegationType == 2 ? "Whole Camect" : "Per Cam Arming"
        const response = await changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, headerData.mame, dispatch)

        if (response) {

            //pass to SiteView to trigger getSingleData Fetch to update camectintegrationType
            dispatch(serviceActions.setCamectIntegationType(CamectIntegationType));
            dispatch(
                uiActions.renderNotification({
                    status: 'successful',
                    title: headerData.tag || headerData.key,
                    message: "Camect integration changed to " + notificationMessage,
                })
            );
        }
    }

    //---------------------------------------------------------------------------------------------------
    const userName = useSelector(state => state.auth.userName);
    const userID = useSelector(state => state.auth.userID);

    const navigate = useNavigate();

    const HandleNavigateToUsersDevices = (UserID, fullName) => {
        //sessionStorage.setItem('ClickedUserID', UserID);
        sessionStorage.setItem('ClickedUserFullName', fullName);
        dataManager.setItem('clickedOnHeader', true)
        dispatch(authActions.setClickedUserDetails({ UserID, fullName }))

        navigate("/AllSites/Users/UserSites");
    }

    //---------------------------------------------------------------------------------------------------
    // ---- gets the current page to display correc header view  ----------------------------------------
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const currentpage = findClosestToEnd(pages, pathArray);

    

    let header;
    switch (currentpage) {

        case "Users":
        case "Activity":
        case "UserSites":
            header =
                <Fragment>
                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName || "..."}</h3>
                    </section>
                </Fragment>
            break
        case "Dashboard":
            header =
                <Fragment>

                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        case "AllSites":
            header =
                <Fragment>
                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        case "Offline":
            header =
                <Fragment>

                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        case "Groups":
            header =
                <Fragment>

                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        case "Padlocks":
            header =
                <Fragment>

                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        case "Sensors":
            header =
                <Fragment>

                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        case "Keyboxes":
            header =
                <Fragment>

                    <section to={"Users/UserSites"} onClick={() => HandleNavigateToUsersDevices(userID, userName)} className={classes.clickable}>
                        <h3 className={classes.title}> Welcome,</h3>
                        <h3 className={classes.text}> {firstName}</h3>
                    </section>
                </Fragment>
            break
        
        case "SiteView":

        

            const camectIntegrationType = headerData.camectIntegrationType;
            const firmwareSplit = headerData?.firmware?.split(".") || [] 
            const firmwareMajor = firmwareSplit[0]
            const firmwareMinor = firmwareSplit[1]
            const hasSwitchableIntegration = (firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9) ? true : false)
            const supportsCamect = (firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7)) ? true : false
            const allowsCamect = headerData.owner

            header =
                <Fragment>
                    <section >
                        <h3 className={classes.title}>Portal site name: </h3>
                        <h3 className={classes.text}> {headerData.tag || "No name set"}</h3>
                    </section>
                    <section>
                        <h3 className={classes.title} >My app site name: </h3>
                        <h3 className={classes.text}>{headerData.deviceName || "Not added to mobile app"}</h3>
                    </section>
                    <section>
                        <h3 className={classes.title}> Site ID: </h3>
                        <h3 className={classes.text}> {headerData.key}</h3>
                    </section>
                    <section>
                        <h3 className={classes.title}> Serial number: </h3>
                        <h3 className={classes.text}> {headerData.subGroup}</h3>
                    </section>
                    <section >
                        <h3 className={classes.title}>IP address: </h3>
                        <div className={classes.SiteStatus}>
                            <h3 className={classes.text}> {headerData.ip}</h3>
                        </div>
                    </section>
                    <section >
                        <h3 className={classes.title}>Firmware: </h3>
                        <div className={classes.SiteStatus}>
                            <h3 className={classes.text}> {headerData.firmware}</h3>
                        </div>
                    </section>
                    <section >
                        <h3 className={classes.title}>Status: </h3>
                        <div className={classes.SiteStatus}>
                            <h3 className={classes.text}> {SiteStatus}</h3>
                            <div style={{ backgroundColor: SiteStatus == "Online" ? "var(--Online-Colour)" : "var(--Offline-Colour)", borderRadius: "50%", width: "12px", height: "12px" }} />
                        </div>
                    </section>
                    <section >
                        <h3 className={classes.title}>CCTV integration: </h3>

                        <div className={classes.SiteStatus}>
                            <h3 className={classes.text}>
                                {camectEnabledCompanies.includes(singleDeviceHeaderData.name) && supportsCamect && hasSwitchableIntegration && (camectIntegrationType == 1 ? "Camect: Normal Mode" : "Camect: Per Camera Arming")}
                                {camectEnabledCompanies.includes(singleDeviceHeaderData.name) && supportsCamect && !hasSwitchableIntegration && ("Camect: Normal Mode")}
                                {camectEnabledCompanies.includes(singleDeviceHeaderData.name) && !supportsCamect && ("Not supported")}
                                {!camectEnabledCompanies.includes(singleDeviceHeaderData.name) && ("None")}
                            </h3>
                        </div>
                    </section>
                    <section>
                        <h3 className={classes.title}>Group: </h3>
                        <h3 className={classes.text}> {headerData.name}</h3>
                    </section>
                </Fragment>
            break
    }
    //---------------------------------------------------------------------------------------------

    // --------------- Mobile SideNav Toggle Through Hamburger Icon  ------------------------------
    const ShowSideNav = useSelector(state => state.ui.showSideNav);

    const handleToggleSideNav = () => {

        dispatch(uiActions.ShowSideNav(!ShowSideNav));
    }

    //---------------------------------------------------------------------------------------------

    //------------------------------ socket connection icon logic to toggle visibility------------------------------------------
    const [showIndicator, setShowIndicator] = useState(false);

    useEffect(() => {
        // Function to toggle the indicator every 30 seconds
        const toggleIndicator = () => {
            setShowIndicator(true);

            // After 5 seconds, hide the indicator
            setTimeout(() => {
                setShowIndicator(false);
            }, 3500);
        };

        toggleIndicator();

        // Start the timer to toggle the indicator every 30 seconds
        const timer = setInterval(toggleIndicator, 15000);

        // Clean up the timer when the component unmounts
        return () => clearInterval(timer);
    }, []); // Empty dependency array ensures the effect runs only once

    //------------------------------------------------------------------------------------------------------------------------------



    return (
        <>
            <Modal ref={ModalDialog} title={"Tag site - " + headerData.key} modalName={"Site View"} HandleShowModal={HandleShowModal}>
                <SetTag placeholder={headerData.key} DeviceUUID={headerData.uuid} HandleCloseModal={HandleCloseModal} dispatch={dispatch} update={update} setUpdate={setUpdate} isManualSet={true} headerData={headerData} setHeaderData={setHeaderData}/>
            </Modal>

            <div className={classes['header-container']}>
                <img className={classes.hambugerIcon} src={hambugerIcon} onClick={handleToggleSideNav} />

                <div className={classes.left}>
                        <img className={logoClass} src={logo} />
                    </div>

                <div className={classes.innerContainer}>

                    

                    <div className={classes.container}>
                        <div className={classes.header}>
                            {header}
                        </div>

                    </div>
                    <div className={classes.right}>
                        {showIndicator && <Puff stroke={isSocketConnected ? "white" : "red"} speed={1.5} />}
                        {/* <NotificationDropDownList title={"Notifications"} dropdownIcon={NotificationBell} classBell={classes.bell} /> */}
                    </div>
                </div>

            </div>
        </>
    );
}

export default Header;