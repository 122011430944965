import React, { useState } from "react";
import classes from './padlocksList.module.css'
import DataManager from "../../hooks/DataManager";
import ListCard from "../../components/GridList/ListCard";
import BasicTable from "../../components/Table/Table/Table";
import Pill_Widget from "../../components/UI/Widgets/Pill_Widget";
import { useDispatch, useSelector } from 'react-redux';
import Button1 from "../../components/UI/Button/Button1";
import { Await, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import useSetCookie from "../../components/hooks/UseSetCookie";
import { serviceActions } from "../../store/service-Slice";
import SearchBar from "../SearchView/Search/SearchBar";

const PadlocksList = ({ locks, filtering, update, setUpdate, isSensors }) => {

    const [iglooLocks, setPadlocks] = useState(locks);
    const token = useSelector(state => state.auth.token);
    const client = useSelector(state => state.auth.client);
    const apiData = {
        token,
        client
    }

    const dataManager = new DataManager();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const HandleUipiodOnClick = (DeviceUUID) => {
        dispatch(serviceActions.setDeviceUUID(DeviceUUID));
        useSetCookie("DeviceUUID", DeviceUUID, 1);
        navigate("/AllSites/SiteView/" + DeviceUUID);
    }

    const batteryColours = (batteryLevel) => {
        let colour = "";
        if (batteryLevel >= 0 && batteryLevel < 25) {
            // red
            colour = "rgb(220,114,119)"
        } else if (batteryLevel >= 25 && batteryLevel < 50) {
            //orange
            colour = "rgb(248,183,131)"
        } else {
            //green
            colour = "rgb(101,151,100)"
        }

        return colour;

    }

    const signalColours = (signal) => {
        let colour = "";
        if (signal >= 0 && signal < 25) {
            // red
            colour = "rgb(220,114,119)"
        } else if (signal >= 25 && signal < 50) {
            //orange
            colour = "rgb(248,183,131)"
        } else {
            //green
            colour = "rgb(101,151,100)"
        }

        return colour;

    }

    let sensorTableColumns = [
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Sensor ID ⇅
                    </h3>
                </div> 
            ),
            accessorKey: 'deviceID',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {row.row.original.deviceID}
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Sensor name ⇅</h3>
                </div> 
            ),
            accessorKey: 'pinName',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
                        {row.row.original.pinName}
                    </div>
                )
            }
        },
        {
            header: () => (
              <div className={classes.tableTitleText} title="Click to sort">
                <h3>Sensor type ⇅</h3>
              </div>
            ),
            accessorKey: 'sensorType',
            cell: (row) => {

                let sensorType = "";
                switch(row.row.original.sensorType){
                    case 'SIGFOX RE:SURE':
                        sensorType = "SigFox lite contact"
                    break;
                    case 'IOTA':
                        sensorType = "SigFox industrial contact"
                    break;
                    case 'KNOWIO Sensor':
                        sensorType = "NB-IOT contact"
                    break;
                    default:
                        sensorType = row.row.original.sensorType;
                    break;
                }

              return (
                <div
                  onClick={() => HandleUipiodOnClick(row.row.original.uuid)}
                  className={classes.tableValueText}
                >
                  {sensorType}
                </div>
              );
            },
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Portal site name ⇅</h3>
                </div>  
            ),
            accessorKey: 'tag',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {row.row.original.tag && row.row.original.tag.trim() !== "" && (
                            <Pill_Widget
                                boolean={true}
                                PillColor_IfTrue={"#919191"}
                                PillColor_IfFalse={"#6e6e6e"}
                                text_IfTrue={row.row.original.tag}
                                className={classes.PillHeight}
                            />
                        )}                
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>My app site name ⇅</h3>
                </div> 
            ),
            accessorKey: 'deviceName',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {row.row.original.deviceName && row.row.original.deviceName.trim() !== "" && (
                            <Pill_Widget
                                boolean={true}
                                PillColor_IfTrue={"#C3B1E1"}
                                PillColor_IfFalse={"#626a67"}
                                text_IfTrue={row.row.original.deviceName}
                                className={classes.PillHeight}
                            />
                        )}                
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Site ID ⇅</h3>
                </div> 
            ),
            accessorKey: 'key',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)}className={classes.tableValueTextBold}>
                        {row.row.original.key}
                    </div>
                )
            }
        },
        {
            header: () => (
              <div className={classes.tableTitleText} title="Click to sort">
                <h3>Battery level ⇅</h3>
              </div>
            ),
            accessorKey: 'battery',
            cell: (row) => {
              const batteryReadAt =
                row.row.original.battery === "Not Read Yet" || row.row.original.battery === "No Data"
                  ? "No data"
                  : row.row.original.battery + "%";
          
              return (
                <div
                  onClick={() => HandleUipiodOnClick(row.row.original.uuid)}
                  className={classes.tableValueText}
                >
                  {batteryReadAt !== "No data" ? (
                    <Pill_Widget
                      boolean={true}
                      PillColor_IfTrue={batteryColours(row.row.original.battery)}
                      PillColor_IfFalse={"#626a67"}
                      text_IfTrue={batteryReadAt}
                      className={classes.PillHeight}
                    />
                  ) : null}
                </div>
              );
            },
          },
          {
            header: () => (
              <div className={classes.tableTitleText} title="Click to sort">
                <h3>Signal strength ⇅</h3>
              </div>
            ),
            accessorKey: 'signal',
            cell: (row) => {
              const signalStrength =
                row.row.original.signal === "Not Read Yet" || row.row.original.signal === "No Data"
                  ? "No data"
                  : row.row.original.signal + "%";
          
              return (
                <div
                  onClick={() => HandleUipiodOnClick(row.row.original.uuid)}
                  className={classes.tableValueText}
                >
                  {signalStrength !== "No data" ? (
                    <Pill_Widget
                      boolean={true}
                      PillColor_IfTrue={signalColours(row.row.original.signal)}
                      PillColor_IfFalse={"#626a67"}
                      text_IfTrue={signalStrength}
                      className={classes.PillHeight}
                    />
                  ) : null}
                </div>
              );
            },
          }
          
    ]

    let tableColumns = [
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Lock ID ⇅
                    </h3>
                </div> 
            ),
            accessorKey: 'lockID',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {row.row.original.lockID}
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Lock name ⇅</h3>
                </div> 
            ),
            accessorKey: 'pinName',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueTextBold}>
                        {row.row.original.pinName}
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Portal site name ⇅</h3>
                </div>  
            ),
            accessorKey: 'tag',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {row.row.original.tag && row.row.original.tag.trim() !== "" && (
                            <Pill_Widget
                                boolean={true}
                                PillColor_IfTrue={"#919191"}
                                PillColor_IfFalse={"#6e6e6e"}
                                text_IfTrue={row.row.original.tag}
                                className={classes.PillHeight}
                            />
                        )}                
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>My app site name ⇅</h3>
                </div> 
            ),
            accessorKey: 'deviceName',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {row.row.original.deviceName && row.row.original.deviceName.trim() !== "" && (
                            <Pill_Widget
                                boolean={true}
                                PillColor_IfTrue={"#C3B1E1"}
                                PillColor_IfFalse={"#626a67"}
                                text_IfTrue={row.row.original.deviceName}
                                className={classes.PillHeight}
                            />
                        )}                
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Site ID ⇅</h3>
                </div> 
            ),
            accessorKey: 'key',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)}className={classes.tableValueTextBold}>
                        {row.row.original.key}
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Battery level ⇅</h3>
                </div> 
            ),
            accessorKey: 'battery',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        <Pill_Widget boolean={true} PillColor_IfTrue={batteryColours(row.row.original.battery)} PillColor_IfFalse={"#626a67"} text_IfTrue={row.row.original.battery + "%"} className={classes.PillHeight} />
                    </div>
                )
            }
        },
        {
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                    <h3>Last read ⇅</h3>
                </div> 
            ),
            accessorKey: 'batteryReadAt',
            cell: (row) => {
                const formattedDate =
                isNaN(new Date(row.row.original.batteryReadAt)) || 
                !row.row.original.batteryReadAt 
                  ? row.row.original.batteryReadAt 
                  : format(new Date(row.row.original.batteryReadAt), "MMMM do, yyyy - HH:mm");
                              return (
                    <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} className={classes.tableValueText}>
                        {formattedDate}
                    </div>
                )
            }
        }
    ]

    const [searchTerm, setSearchTerm] = useState("");
    if (isSensors) {
        tableColumns = sensorTableColumns
    };

    return (
        <div className={`${classes.table} ${classes.center} `}>
            <ListCard secondaryClassName={classes["Card-container"]}>
                <div className={classes["tableCardLayoutUI-wrapper"]} style={{ padding: "5px 10px 0px 10px" }}>
                    <Await resolve={locks}>
                        {(loadedtableData) => {
                            // State to store search input
                            
    
                            // Function to filter data
                            const filteredData = loadedtableData.filter((item) =>
                                // Adjust this condition based on the properties you want to search
                                Object.values(item)
                                    .some(value => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                            );
    
                            return (
                                <>
                                    {/* Search bar */}
                                    <div className={classes.searchContainer}>
                                        <SearchBar
                                            type="text"
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            style={{
                                                
                                                padding: "5px 8px", // Adjust padding to reduce height
                                                width: "300px",    // Set a specific width
                                                fontSize: "14px",  // Adjust font size for better proportions
                                                boxSizing: "border-box", // Ensure consistent sizing
                                                borderRadius: "4px", // Add slight rounding for aesthetics
                                                border: "1px solid #ccc" // Define a subtle border
                                            }}
                                        />
                                    </div>
    
                                    {/* Table */}
                                    <BasicTable
                                        data={filteredData}
                                        columns={tableColumns}
                                        filtering={filtering}
                                        rowClick={false}
                                        ShowPagination={false}
                                        PageSize={5000}
                                        paginationOptions={[25, 50, 100]}
                                        showFilterButton={false}
                                        isSortable={true}
                                    />
                                </>
                            );
                        }}
                    </Await>
                </div>
            </ListCard>
        </div>
    );
}

export default PadlocksList;