import React, { useMemo, useEffect, Suspense, useState, useRef } from 'react'

import { DateTime } from 'luxon';

import classes2 from "./UsersTableLayout.module.css";
import classes from "./TableLayout.module.css";
import Tableclasses from "./Table/Table.module.css";
import Card from "./Card";
import BasicTable from './Table/Table';
import TableButton from './Table/TableButton/TableButton';
import fiiller_rows from './Table/MOCK_DATA_3.json'
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import UseDateFormat from '../hooks/UseDateFormat';
import Pill_Widget from '../UI/Widgets/Pill_Widget';
import DropdownList from '../UI/Cards/DropdownList';

import diableNotificationIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import SettingsCog from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"

import SuspendDeviceIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"
import UnSuspendDeviceIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Unlocked.svg"

import ClearLogsIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import ChangeOwnerIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Change User.svg"

import ChangeCamectIntergrationTypeIcon_Off from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - Off.svg";
import ChangeCamectIntergrationTypeIcon_On from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - On.svg";

import CopyDeviceSettingsIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Logs.svg";
import RebootDeviceIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__OffButton.svg";
import ResetDeviceIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Refresh.svg"
import { useDispatch, useSelector } from 'react-redux';
import { changeCamectIntegration, clearDeviceLogs, getDevices, rebootIPIODevice, resetIPIODevice, suspendDevice } from '../../Api Methods/Api';
import { serviceActions } from '../../store/service-Slice';
import useSetCookie from '../hooks/UseSetCookie';
import Modal from '../Modal/Modal';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import DropDownForm from '../forms/DropDownForm';
import InputForm from '../forms/InputForm';

const UsersTableLayout = ({ devices, filtering, apiData }) => {
  const [SiteDevices, setSiteDevices] = useState(devices)


  useEffect(() => {
    setSiteDevices(devices)

  }, [devices])

  const HandleChangeOwner = async (result) => {

    // const data = await Sites;
    const data = await devices;

    // Find the row in the data array and update its name
    const updatedData = data.map(row => {
      if (row.uuid === result.DeviceUUID) {
        return { ...row, name: result.dropDown };
      }
      return row;
    });

    // Update the state with the new data
    setSiteDevices(updatedData);
  };

  //---------------------------------------------------------------------------
  useEffect(() => {
    const GetDevices = () => {
      const responseData = getDevices(apiData, companyName);
      setSiteDevices(responseData)
    }

    // Set up a timer to fetch data every 30 seconds
    const timerId = setInterval(() => {
      GetDevices(apiData, companyName);
    }, 60000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(timerId);
  }, [])
  //---------------------------------------------------------------------------

  const companyName = useSelector(state => state.auth.companyName);
  const userID = useSelector(state => state.auth.userID);
  const DeviceKey = sessionStorage.getItem("DeviceKey")

  // -- Controls the settingCog wheel -------------------------------------- 
  const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

  const handleSettingsClick = (rowId) => {
    // If the clicked dropdown is already open, close it
    if (openDropdownRowId === rowId) {
      setOpenDropdownRowId(null);
    } else {
      // Otherwise, open the clicked dropdown
      setOpenDropdownRowId(rowId);
    }
  };
  // -----------------------------------------------------------------------


  //------------------ handling the closing of the setting cog wheel drop down by clicking else where -----------------------------------

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown menu, so close it
        setOpenDropdownRowId(null);
      }
    };

    // Add event listener to listen for clicks outside the dropdown menu
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Cleanup: Remove event listener when component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  //------------------------------------------------------------------------

  //------------------------------- Modal Logic  ----------------------------------------------

  const ModalDialog = useRef();

  const HandleShowModal = () => {
    ModalDialog.current.open();
  }

  const HandleCloseModal = () => {
    ModalDialog.current.close();
  }

  const [modalTitle, setModalTitle] = useState("title")
  const [modalComponent, setModalComponent] = useState()
  //---------------------------------------------------------------------------------------------



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleUipiodOnClick = (DeviceUUID) => {

    dispatch(serviceActions.setDeviceUUID(DeviceUUID));

    useSetCookie("DeviceUUID", DeviceUUID, 1);

    navigate("/portal/SiteView/" + DeviceUUID);
  }

  // ---------- carrys out clicked action in dropdown
  const UserActionHandler = (userAction, suspend, DeviceId, DeviceUUID, userID, DeviceName, DeviceKey, camectIntegationType) => {
    switch (userAction) {
      
      case 'Unsuspend Device':
      case 'Suspend Device':

        const SuspendDevice = async () => {
          const responseData = await suspendDevice(apiData, suspend, DeviceId);
          setOpenDropdownRowId(null);
        }
        SuspendDevice();
        break;
      case 'Clear Logs':
        const ClearLogs = async () => {
          clearDeviceLogs(apiData, DeviceUUID, userID, dispatch);
          setOpenDropdownRowId(null);
        }
        ClearLogs();
        break;
      case 'Change group':

        const ChangeOwner = async (DeviceKey) => {
          setModalTitle("Change group for: " + DeviceKey)
          setModalComponent(<DropDownForm apiData={apiData} DeviceUUID={DeviceUUID} label={"Please select a new site group below"} dropDownOptions={['EyeSpyFX', 'RE:SURE', 'PARFAS', 'PORTAL_SECURITY', 'DCC', 'SOLO', 'CSC', 'CPS', 'EES', 'WEEBIG']} HandleCloseModal={HandleCloseModal} HandleChangeOwner={HandleChangeOwner} />
          )
          setOpenDropdownRowId(null);
          HandleShowModal()
          // inviteUser(apiData, DeviceId, userID, userRole, email, deviceName)
        }
        ChangeOwner(DeviceKey);

        break;
      case 'Change camect integration type':
        const Changecamectintergrationtype = async () => {

          const CamectIntegationType = camectIntegationType == 2 ? 2 : 1;

          changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, DeviceName)
          setOpenDropdownRowId(null);
        }
        Changecamectintergrationtype();
        break;
      case 'Copy device settings':
        const Copydevicesettings = async (DeviceKey) => {
          setModalTitle("Copy settings of: " + DeviceKey)
          setModalComponent(
            <div>
              <InputForm apiData={apiData} DeviceUUID={DeviceUUID} label={"Enter Device UIPIOID to copy Settings to"} />
            </div>
          )
          setOpenDropdownRowId(null);
          HandleShowModal()
          // inviteUser(apiData, DeviceId, userID, userRole, email, deviceName)
        }
        Copydevicesettings(DeviceKey);
        break;
      case 'Reboot Device':
        const RebootDevice = async () => {


          rebootIPIODevice(apiData, DeviceUUID, userID, DeviceName, dispatch);
          setOpenDropdownRowId(null);
        }
        RebootDevice();
        break;
      case 'Reset Device':
        const ResetDevice = async () => {
          setOpenDropdownRowId(null);
        }
        ResetDevice();
        break;
      default:
        console.warn('Invalid option:', userAction);
    }
  }


  const fiillerRows = useMemo(() => fiiller_rows, []);

  const tablecolumns = [
    {
      header: 'Tag',
      accessorKey: 'tag',
      cell: (row) => {

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.tag}
          </div>
        )
      }
    },
    {
      header: 'Site ID',
      accessorKey: 'key',
      cell: (row) => {

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.key}
          </div>
        )
      }
    },
    {
      header: 'Serial Number',
      accessorKey: 'subGroup',
      cell: (row) => {
        const subGroup = row.row.original.subGroup;
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {subGroup != "" ? subGroup : "-"}
          </div>
        )
      }
    },
    {
      header: 'IP Address',
      accessorKey: 'ip',
      cell: (row) => {

        let ip;
        // if (row.row.original.ip === "") {
        //   ip = "-";
        // }
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.type == "CC" ? "IPIO CC" : row.row.original.ip}
          </div>
        )
      }
    },
    {
      header: 'Firmware',
      accessorKey: 'firmware',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.firmware}
          </div>
        )
      }
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row) => {
        const OnlineStatus = row.row.original.online == "YES" ? true : false
        const SuspendedStatus = row.row.original.status == "suspended";
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content", display: "flex", gap: "5px" }}>
            <Pill_Widget boolean={OnlineStatus} PillColor_IfTrue={"#22ca80"} PillColor_IfFalse={"#ff4f70"} text_IfTrue={"Online"} text_IfFalse={"Offline"} />
            {SuspendedStatus && < Pill_Widget boolean={SuspendedStatus} PillColor_IfTrue={"black"} PillColor_IfFalse={"none"} text_IfTrue={"Suspended"} />}
          </div>
        )
      }
    },
    {
      header: 'Initial Activation',
      accessorKey: 'created',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {UseDateFormat(row.row.original.created)}
          </div>
        )
      }
    },
    {
      header: 'Users',
      accessorKey: 'userCount',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.userCount}
          </div>
        )
      }
    },
    {
      header: 'Owned by',
      accessorKey: 'name',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.name}
          </div>
        )
      }
    },
    {
      id: 'actions',
      cell: (row) => {
        const items = [
          {
            image:
              row.row.original.status !== "suspended"
                ? SuspendDeviceIcon
                : UnSuspendDeviceIcon,
            title:
              row.row.original.status !== "suspended"
                ? "Suspend Device"
                : "Unsuspend Device",
          },
          {
            image: ClearLogsIcon,
            title: "Clear Logs",
          },
        ];

        const firmware = row.row.original.firmware.split('.')
        const firmwareMajor = firmware[0]
        const firmwareMinor = firmware[1]
        const DeviceType = row.row.original.type;
        const isOnline = row.row.original.online == "YES" ? true : false;
        const deviceDetails = JSON.parse(row.row.original.deviceDetails)
        const camectIntegationType = deviceDetails.camectIntegrationType;


        if (companyName == "EyeSpyFX") {
          items.splice(2, 0, {
            image: ChangeOwnerIcon,
            title: "Change group",
          });
        }

        // Conditionally push the "Change Owner" object based on firmware version and device type
        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
          items.splice(3, 0, {
            image: camectIntegationType == 2 ? ChangeCamectIntergrationTypeIcon_On : ChangeCamectIntergrationTypeIcon_Off,
            title: "Change camect integration type",
          },);
        }

        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7))) {
          items.splice(4, 0, {
            image: CopyDeviceSettingsIcon,
            title: "Copy device settings",
          });
        }

        // if (isOnline) {
        //   items.splice(5, 0, {
        //     image: RebootDeviceIcon,
        //     title: "Reboot Device",
        //   },
        //     {
        //       image: ResetDeviceIcon,
        //       title: "Reset Device",
        //     });
        // }

        const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
        const suspend = row.row.original.status == "suspended" ? "available" : "suspended";

        return <DropdownList
          item={row.row.id}
          icon={SettingsCog}
          onClick={handleSettingsClick}
          ifSettingsCogIsClicked={ifSettingsCogIsClicked}
          ref={dropdownRef}
          title={"Actions"}
          className={classes.container}
          onUserActionClick={(userAction) =>
            UserActionHandler(
              userAction,
              suspend,
              row.row.original.id,
              row.row.original.uuid,
              userID,
              row.row.original.name,
              row.row.original.key,
              camectIntegationType
            )
          }
          listItems={items}
        />
      },
    }
  ];

  let tableData = SiteDevices;

  return (
    <>
      <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
        {modalComponent}
      </Modal>
      <div className={`${classes.table} ${classes.center} `}>
        <Card secondaryClassName={classes["Card-container"]}>

          <div className={classes["tableCardLayoutUI-wrapper"]}>
            <div className={classes["top-wrapper"]}>
              <h1>Devices</h1>
              {/* <TableButton title={"Add User"} onClick={HanldeshowAddUserForm} /> */}
            </div>
            <Suspense fallback={
              <div className={classes["loadingSpinner-container"]}>
                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
              </div>
            }>
              <Await resolve={SiteDevices}>
                {(loadedtableData) =>
                  <BasicTable data={loadedtableData} columns={tablecolumns} filtering={filtering} rowClick={false} ShowPagination={true} PageSize={50} paginationOptions={[25, 50, 100]} showFilterButton={true} />
                }
              </Await>
            </Suspense>
          </div>
        </Card >
      </div >
    </>
  )
}

export default UsersTableLayout;