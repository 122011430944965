import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clearDeviceLogs, UpdateDeviceDetails, UpdateDeviceDetailsExtras } from '../../../../Api Methods/Api';
import ComponentCard from '../../Cards/ComponentCard/ComponentCard'
import EditPinForm from '../../Cards/FormCard/EditPinForm'

import classes from './EditPin.module.css'

function overwriteKeyInObject(obj, key, newValue) {
    return {
        // spread operator used to create shallow copy of original object.    
        ...obj,
        // the key is used to get a hold of an existing key which we can use to pass a new value to - this being newValue
        [key]: newValue
    };
}

const EditPin = ({ PinDetails, HandleToggleEditPinView, setPinEdited }) => {

    const [newDeviceDetails, setNewDeviceDetails] = useState(null);
    const [newDeviceDetailsExtras, setNewDeviceDetailsExtras] = useState(null)


    let DeviceID = useSelector(state => state.service.DeviceID);
    let DeviceDetails = useSelector(state => state.service.DeviceDetails);
    let DeviceDetailsExtras = useSelector(state => state.service.DeviceDetailsExtras);

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);

    const apiData = {
        token,
        client,
    }

    const dispatch = useDispatch();

    //so when the user clicks update on the EditPinForm as now it it populated with new data
    useEffect(() => {

        if (newDeviceDetails != null) {

            let keyToOverwrite = PinDetails.InputPin ? PinDetails.InputPin.Pin : PinDetails.Pin
            const updatedDeviceDetails = overwriteKeyInObject(DeviceDetails, keyToOverwrite, newDeviceDetails[keyToOverwrite]);

            // adding key on to object as server is expecting it
            let newDetails = {
                "deviceDetails": updatedDeviceDetails
            }

            const UpdateDevice = async () => {
                const response = await UpdateDeviceDetails(apiData, newDetails, DeviceID, dispatch);
                if (response) {
                    //switch back to default AppView
                    HandleToggleEditPinView();
                    setPinEdited(true);
                    //setHasDeviceChanged(true)
                    
                    //refresh site???
                }
            }
            UpdateDevice();

            //reset
            setNewDeviceDetails(null);
        }

        if (newDeviceDetailsExtras != null) {

            let keyToOverwrite = PinDetails.InputPin ? PinDetails.InputPin.Pin : PinDetails.Pin
            const updatedDeviceDetails = overwriteKeyInObject(DeviceDetailsExtras, keyToOverwrite, newDeviceDetailsExtras[keyToOverwrite]);
            
            // adding key on to object as server is expecting it
            let newDetailsExtras = {
                "deviceExtraDetails": updatedDeviceDetails
            }

            const UpdateDeviceExtras = async () => {
                const response = await UpdateDeviceDetailsExtras(apiData, newDetailsExtras, DeviceID, dispatch);
                if (response) {
                    //switch back to default AppView
                    HandleToggleEditPinView()
                    setPinEdited(true)
                }
            }
            UpdateDeviceExtras();

            //reset
            setNewDeviceDetailsExtras(null);
        }
    }, [newDeviceDetails, newDeviceDetailsExtras])


    return (
        <ComponentCard id="OutPutPinListCard" className={classes["pin-switches"]}>
            <EditPinForm PinDetails={PinDetails} setNewDeviceDetails={setNewDeviceDetails} setNewDeviceDetailsExtras={setNewDeviceDetailsExtras} setPinEdited={setPinEdited} />
        </ComponentCard>
    )
}

export default EditPin