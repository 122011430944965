import React, { useMemo, useEffect, Suspense, useState, useRef } from 'react'
import classes from './GroupsGrid.module.css'
import GroupCard from './GroupCard';
import Card from './Card';
import DataManager from '../../hooks/DataManager';
import DeviceLoadingGrid from '../../components/GridList/DeviceLoadingGrid';
import { Await, useNavigate } from 'react-router-dom';
import SearchBar from '../SearchView/Search/SearchBar';
import BasicTable from '../../components/Table/Table/Table';
import Pill_Widget from '../../components/UI/Widgets/Pill_Widget';
import UseDateFormat from '../../components/hooks/UseDateFormat';
import DropdownList from '../../components/UI/Cards/DropdownList';
import AddUserIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/addUser.png'
import renameGroupIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Edit-93.svg'
import deleteGroupIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/deleteGroup.png'
import SettingsIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/settings.png'
import check from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/check.png';
import ParentIndicator from './ParentIndicator';
import { useDispatch, useSelector } from 'react-redux';
import useClickOutside from '../../components/hooks/useClickOutside';
import Button1 from '../../components/UI/Button/Button1';
import FilterUsersSearch from '../../components/PowerSearch/FilterUsersSearch';
import { addUserToGroup, deleteGroup, renameGroup } from '../../Api Methods/Api';
import DropDownForm from '../../components/forms/DropdownCreateGroup';
import InputFormCreateGroup from '../../components/forms/InputFormCreateGroup';
import Modal from '../../components/Modal/Modal';

const GroupsGrid = ({GroupsList, setUpdate, update}) => {
    const dataManager = new DataManager();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const dispatch = useDispatch();
    const [name, setName] = useState();
    const [selectionMade, setSelectionMade] = useState(false);
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const apiData = {
        token,
        client
    }
    const [title, setTitle] = useState('');
    const [modalComponent, setModalComponent] = useState();
    const ModalDialog = useRef();
    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const [groupID, setGroupID] = useState();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    };

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        setName("")
        setSuggestions([])
        setSelectionMade(false);
    };

    const handleSettingsClick = (rowId) => {
        if (openDropdownRowId === rowId) {
            setOpenDropdownRowId(null);
        } else {
            setOpenDropdownRowId(rowId);
        }
    };

    useEffect(() => {
        const handleGroupsFilter = () => {
          const filtered = GroupsList.filter((item) =>
            Object.values(item)
              .some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
          );
          dataManager.setItem('groupSearchTerm', searchTerm); // Store search term
          setFilteredData(filtered); // Update filtered data
        };
      
        if (searchTerm !== dataManager.getItem('groupSearchTerm')) {
            handleGroupsFilter(); // Call filtering function
        }
      }, [searchTerm]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Clicked outside the dropdown menu, so close it
                setOpenDropdownRowId(null);
            }
        };

        // Add event listener to listen for clicks outside the dropdown menu
        document.addEventListener('click', handleClickOutside);

        return () => {
        // Cleanup: Remove event listener when component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (suggestions.length > 0 || selectionMade) {
            showAddUserModal();
        }
    }, [suggestions])

    useEffect(() => {
        if (name !== "" && name !== undefined){
            showAddUserModal();
        } 
    }, [name])

    const updateName = (name) => {
        setName(name);
        setSuggestions([]);
        dataManager.setItem('userToInvite', name);
        showAddUserModal();
        setSelectionMade(true);
    }

    const showAddUserModal = () => {
        setTitle("Add user")
        setModalComponent(
          <div>
            <div>
            <FilterUsersSearch searchQuery={name} setSearchQuery={setName} placeholder={"Enter users email address"} setIsLoading={false} setFilteredResults={setSuggestions} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]}></FilterUsersSearch>
            </div>
            {suggestions.length > 0 && (
              <ul
                  style={{
                      listStyle: 'none',
                      margin: 0,
                      padding: '8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      position: 'absolute',
                      width: '100%',
                      backgroundColor: '#fff',
                      zIndex: 1000,
                  }}>
  
                  {suggestions.map((suggestion, index) => (
                      <li
                          key={index}
                          onClick={() => updateName(suggestion)}
                          style={{
                              padding: '8px',
                              cursor: 'pointer',
                              borderBottom: '1px solid #ddd',
                          }}
                      >
                          {suggestion}
                      </li>
                  ))}
              </ul>
            )}     
            <br></br>
            <div style={{fontWeight: 300, fontStyle: 'italic'}}><p>Please note, users must have an existing mobile app account before being invited to a group.</p></div>
            <br></br>
            <div>
            </div>
            <br></br>
            <Button1 onClick={addUser}>Add</Button1>
          </div>
        )  
        HandleShowModal();
    }

    const addUser = async () => {
        const email = name
        
        if (email != ''){

          let body = {
              groupID: groupID, 
              email: email, 
              role: "TechSupport", 
              owner: dataManager.getItem('isChecked') ? 1 : 0,
          }

          const response = await addUserToGroup(apiData, body, dispatch)
          if (response.success){
              dataManager.setItem('userToInvite', "");
              dataManager.setItem('isChecked', false);
              setUpdate(update+1);
              HandleCloseModal();
              successModal();
          } else {
              //error
              showError(response);
          }
        }
    }

    // const showTransferGroupModal = () => {
    //     setTitle("Transfer group")
    //     const groupNames = groups.map(group => group.name);
    //     setModalComponent(
    //       <div>
    //         <div>
    //         <DropDownForm label={"Please select the new group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
    //       </div>
    //       <br></br>
    //         <Button1 onClick={transferGroup}>Transfer</Button1>
    //       </div>
    //     )  
    //     HandleShowModal();
    // }

    const showDeleteGroupModal = (groupID, parentName) => {
        setTitle("Delete group")
        setModalComponent(
          <div>
            <div style={{textAlign: "center"}}>
              <h2>Are you sure you want to delete this group?</h2>
              <h3 style={{textAlign:"center"}}>This action cannot be undone!</h3>
            </div>
            <br></br>
            <div>
              <p style={{fontStyle: 'italic', textAlign:"center"}}>{`All sites within this group will be moved to ${parentName}`}</p>
            </div>
            <br></br>
            <div style={{ 
                  display: "flex", 
                  flexDirection: "column", 
                  alignItems: "center", 
                  gap: "10px" // Adds space between buttons
                }}>
              <Button1 onClick={HandleCloseModal}>No, cancel</Button1>
              <Button1 onClick={() => deleteGroupAPI(groupID)}>Yes, I'm sure</Button1>
            </div>
          </div>
        ) 
  
        HandleShowModal();
    }

    const deleteGroupAPI = async (groupID) => {
        const response = await deleteGroup(apiData, groupID, dispatch)
        if (response.success){
        //refresh
            setUpdate(update+1);
            HandleCloseModal();
            successModal();
        } else {
            showError(response);
        
        }
    }

    const showError = (response) => {
        setTitle("Error")
        setModalComponent(
          <div>
          <div>
            <h3 style={{textAlign: "center"}}>Error</h3>
          </div>     
          <div>
            <p style={{textAlign: "center"}}>{response.msg}</p>
          </div> 
          <div style={{ 
                  display: "flex", 
                  flexDirection: "column", 
                  alignItems: "center", 
                  gap: "10px" // Adds space between buttons
                }}>
          <Button1 onClick={HandleCloseModal}>Close</Button1>
          </div>
        </div>
        ) 
  
        HandleShowModal();
    }

    const showRenameGroupModal = (groupID, groupName) => {
        setTitle("Rename group")
        setModalComponent(
          <div>
          <div>
            <InputFormCreateGroup label={"Enter new group name"} setName={setName}></InputFormCreateGroup>
          </div>     
          <br></br>
          <Button1 onClick={() => RenameGroup(groupID, groupName)}>Rename</Button1>
        </div>
        ) 
  
        HandleShowModal();
    }

    const RenameGroup = async (groupID, groupName) => {
        const newName = dataManager.getItem('groupName');
        const oldName = groupName;
        let body = {
            groupID: groupID, 
            oldName: oldName, 
            newName: newName
        };

        const response = await renameGroup(apiData, body, dispatch)
        if (response){
            setUpdate(update+1);
            HandleCloseModal();
            successModal();
        } else {
            //error
        }
    }

    const UserActionHandler = (userAction, groupID, parentName, groupName) => {
        setGroupID(groupID)
        switch (userAction){
         case 'Add user': 
           showAddUserModal();
         break;
         case 'Delete group':
           dataManager.setItem('groupClickedID', groupID);
           showDeleteGroupModal(groupID, parentName);
         break;
         case 'Rename group':
           dataManager.setItem('groupClickedID', groupID);
           showRenameGroupModal(groupID, groupName);
         break;
        }
    }

    const successModal = () => {
      setTitle('Success')
      setModalComponent(
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <img className={classes.ModalIcon} src={check}>
          </img>
          <br></br>
          <br></br>
          <div>
              <h3 style={{ textAlign: "center", color:"#858585"}}>Action completed successfully</h3>
          </div>   
          <br></br>
          <br></br>
          <div>
            <br></br>
            <Button1 onClick={HandleCloseModal} style={{width:"100%"}}>Close</Button1>
          </div>
        </div>
      )
      HandleShowModal();
    }

    const handleFilterLogic = async (data, searchQuery, selectedCategories) => {
        const users = await data;
        const usersEmails = users.map(user => user.email);
        setSuggestions(usersEmails);
    };

    const handleGroupClicked = (groupName, groupID, groupPath) => {
        dataManager.setItem('groupClicked', groupName)   
        dataManager.setItem('groupPath', groupPath)
        dataManager.setItem('groupClickedID', groupID)   

        navigate("/AllSites/Groups/GroupSites")
    }

    let tableColumns = [
        {
            id: 'groupName',
            header: () => (
              <div className={classes.tableTitleText} title="Click to sort">
                <h3>Group name</h3>
              </div>
            ),
            filterLabel: "group name",
            accessorKey: 'name',
            cell: (row) => {
              const name = row.row.original.name;
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div onClick={() => handleGroupClicked(row.row.original.name, row.row.original.id, row.row.original.group_path)} style={{ cursor: "pointer", width: "fit-content" }}>
                    {row.row.original.name && row.row.original.name.trim() !== "" && (
                      <Pill_Widget
                        boolean={true}
                        PillColor_IfTrue={"#9a9a9a"}
                        PillColor_IfFalse={"#9a9a9a"}
                        text_IfTrue={row.row.original.name}
                        className={classes.PillHeight}
                      />
                    )}
                  </div>
                </div>
              );
            }, 
        },
        {
            id: 'parentName',
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                <h3>Group parent</h3>
            </div>
            ),
            filterLabel: "parentName",
            accessorKey: 'parent_name',
            cell: (row) => {
                return (
                <div onClick={() => handleGroupClicked(row.row.original.name, row.row.original.id, row.row.original.group_path)} className={classes.tableValueText}>
                    {row.row.original.parent_name && row.row.original.parent_name.trim() !== "" && (
                        <ParentIndicator companyName={row.row.original.parent_name}/>
                    )}
                </div>
                )
            }
        },
        {
            id: 'key',
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                <h3>Number of sites</h3>
                </div>
            ),
            filterLabel: "siteCount",
            accessorKey: 'siteCount',
            cell: (row) => {
        
                return (
                <div onClick={() => handleGroupClicked(row.row.original.name, row.row.original.id, row.row.original.group_path)} className={classes.tableValueTextBold}>
                    {row.row.original.deviceCount}
                </div>
                )
            }
        },
        {
            id: 'userCount',
            header: () => (
                <div className={classes.tableTitleText} title="Click to sort">
                <h3>Number of portal users </h3>
                </div>
            ),
            filterLabel: "userCount",
            accessorKey: 'userCount',
            cell: (row) => {
                return (
                    <div onClick={() => handleGroupClicked(row.row.original.name, row.row.original.id, row.row.original.group_path)} className={classes.tableValueTextBold}>
                        {row.row.original.userCount}
                    </div>
                )
            } 
        }, 
        {
            id: 'groupSettings', 
            cell: (row) => {
                let items = [
                    {
                        image: AddUserIcon,
                        title: "Add user"
                    },
                    {
                        image: renameGroupIcon,
                        title: "Rename group"
                    },
                    {
                        image: deleteGroupIcon,
                        title: "Delete group"
                    }
                ];
                
                const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
                return <DropdownList
                    item={row.row.id}
                    icon={SettingsIcon}
                    onClick={handleSettingsClick}
                    ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                    ref={dropdownRef}
                    title={"Actions"}
                    className={classes.container}
                    onUserActionClick={(userAction) => UserActionHandler(userAction, row.row.original.id, row.row.original.parent_name, row.row.original.name)}
                    listItems={items}
                />
            }
        },
    ];

    return (
        <div className={`${classes.table} ${classes.center} `}>
          <div className={classes["tableCardLayoutUI-wrapper"]}>
            <div className={classes["top-wrapper"]}>
            </div>
            <Suspense fallback={
               <div style={{ display: "flex", width: "100%" }}>
                  <DeviceLoadingGrid
                    CardCount={20}
                  />
                </div>
            }>
  
              <Await resolve={GroupsList}>
                {(loadedtableData) => {
                    const filteredData = loadedtableData.filter((item) =>
                      // Adjust this condition based on the properties you want to search
                      Object.values(item)
                          .some(value => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                  );
                  return (
                    <>
                    <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
                        {modalComponent}
                    </Modal>

                     <div className={classes.searchContainer}>
                        <SearchBar
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                padding: "10px", // Adjust padding to reduce height
                                width: "100%",    // Set a specific width
                                fontSize: "14px",  // Adjust font size for better proportions
                                boxSizing: "border-box", // Ensure consistent sizing
                                borderRadius: "4px", // Add slight rounding for aesthetics
                            }}
                        />
                      </div>
                
                  <BasicTable data={filteredData} columns={tableColumns} filtering={""} rowClick={false} ShowPagination={false} PageSize={500} paginationOptions={[25, 50, 100]} showFilterButton={false} HandleTableFiltering={""} />
                </>
            )}}
            </Await>
            </Suspense>
        </div>
      </div>


        
       
    )
}

export default GroupsGrid