import React, { useState } from 'react'
import { setTag } from '../../../Api Methods/Api';
import Input from '../../CustomDropDown/Input/Input';
import Button1 from '../../UI/Button/Button1';
import classes2 from "../../forms/InviteUserForm.module.css"
import classes from "./SetTag.module.css"
import { GetSingleDeviceData } from '../../../store/service-actions';
import { serviceActions } from "../../../store/service-Slice";

const SetTag = ({ placeholder, DeviceUUID, HandleCloseModal, dispatch, setUpdateTag, update, setUpdate, siteDevices, setSiteDevices, siteData, setSiteData, isManualSet, headerData, setHeaderData, successModal, errorModal }) => {

    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');

    const [tag, SetTag] = useState('');

    const handleInputChange = (event) => {
        SetTag(event.target.value);
    };

    const handleCancel = () => {
        SetTag('');
        HandleCloseModal();
    };

    const handleSaveTag = async () => {
        const apiData = {
            token,
            client,
        }

        const response = await setTag(apiData, DeviceUUID, tag, dispatch);
        if (response) {
            setUpdateTag && setUpdateTag(tag);
            HandleCloseModal();
            setUpdate(update+1);
            successModal("Portal site name successfully updated");

            if (siteData){
                siteData[1].tag = tag
                dispatch(serviceActions.updatingSingleDeviceData(siteData[1]));
                console.log(siteData)
                
            }
            
            //dispatch(getSingleDeviceData(DeviceUUID, apiData))
            if (setSiteDevices){
                setSiteDevices((prevSiteDevices) =>
                    prevSiteDevices.map((device) =>
                        device.uuid === DeviceUUID ? { ...device, tag: tag } : device
                    )
                );
            }

            if (isManualSet){
               
                setHeaderData((prevState) => ({
                    ...prevState, // Copy existing state
                    tag: tag, // Update the `online` value
                }));
                
            }
        } else {
            errorModal("Failed to update portal site name, please try again")
        }
    }

    return (
        <form onSubmit={handleSaveTag} className={classes2["container"]} method="dialog" >
            <ul className={classes2["inputs-list"]}>
                <div className={classes2.label}>Set portal name</div>
                <li className={classes2["input-container"]}>

                    <Input
                        type="text"
                        value={tag}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                    />
                </li>
                <div className={classes.form}>
                    <Button1 type="button" onClick={handleCancel}> Cancel </Button1>
                    <Button1 type="submit"> Save  </Button1>
                </div>
            </ul>
        </form>
    )
}

export default SetTag;